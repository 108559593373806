import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { validateAccess } from '../../../Components/User/User';

export default function AccountToolbox(props) {
	const { t } = useTranslation();
	return (
		<div className="recipes-tool-box-items recipes-tool-box-first-row ">
			{props.createNew && validateAccess(null, 'admin') ? (
				<div className="recipes-tool-box-item" title="Create" alt="Create">
					<i className="fa-solid fa-solid fa-plus duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						<Link to={'/Users/create'} className="remove-underline">
							{t('Create new users')}
						</Link>
					</span>
				</div>
			) : (
				''
			)}
			<div className="clear"></div>
		</div>
	);
}
