import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Technician() {
	const { t } = useTranslation();
	return (
		<>
			<h1>{t('Technician')}</h1>
		</>
	);
}
