import React from 'react';

export default function HtmlTestPage() {
	return (
		<>
			<h1>Headline</h1>
			<h2>Sub title</h2>
			<h3>Focus information</h3>
			<h4>Interest</h4>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim id est laborum.
			</p>
			<div>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim id est laborum.
			</div>
			<ul>
				<li>Test 1</li>
				<li>Test 2</li>
				<li>Test 3</li>
				<li>Test 4</li>
			</ul>

			<table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Description</th>
						<th>Date</th>
						<th>Area</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Rasmus Sørense</td>
						<td>Is a fishing man</td>
						<td>12-03-2022 10:45</td>
						<td>My house</td>
					</tr>
					<tr>
						<td>Chano-Bo Iversen</td>
						<td>Is a fishing man to</td>
						<td>12-03-2022 12:45</td>
						<td>His house</td>
					</tr>
					<tr>
						<td>Jasmin R Sørensen</td>
						<td>Nothing but music</td>
						<td>12-02-2021 18:45</td>
						<td>Her rum</td>
					</tr>
				</tbody>
			</table>
			<h1>
				Big <small>Small</small>
			</h1>
		</>
	);
}
