import React from 'react';

export default function Error404() {
	return (
		<div className="main-error-404">
			<div className="main-error-404-inner">
				<h1>Error 404</h1>
				<h4>Page not found</h4>
			</div>
		</div>
	);
}
