export const setLocalStorage = function (key, value) {
	if (value && (value.constructor === Array || value.constructor === Object)) {
		value = JSON.stringify(value);
	}

	/*
	if (typeof value === 'object' || typeof value === 'array') {
		
	}*/
	//window.sessionStorage.setItem(key, value);
	localStorage.setItem(key, value);
};

export const getLocalStorage = function (key) {
	//const stringData = window.sessionStorage.getItem(key);
	const stringData = localStorage.getItem(key);

	if (
		stringData !== null &&
		stringData.trim() !== '' &&
		stringData.charAt(stringData.length - 1) === '}' &&
		stringData.charAt(0) === '{'
	) {
		return JSON.parse(stringData);
	}

	if (
		stringData === null ||
		stringData.trim() === '' ||
		stringData.trim() === 'false'
	) {
		return false;
	}
	if (stringData.trim() === 'true') {
		return true;
	}

	return stringData;
};
