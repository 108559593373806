import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCookbookApi } from '../../Components/CookbookApi';
import { getLocalStorage } from '../../Components/LocalStorage/LocalStorage';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import { validateAccess } from '../../Components/User/User';
import AccountForm from './components/AccountForm';

function AccountEdit(props) {
	const userData = getLocalStorage('user_data');
	const { t } = useTranslation();

	const { userId } = useParams();

	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Users/' + (userId ? userId : userData.id),
		translate: t,
	});

	var breadcrumb = [{ link: '/Users', name: t('Users') }];

	if (apiData && apiData.data.username) {
		breadcrumb.push({
			name: apiData.data.username,
		});

		breadcrumb.push({
			name: t('Edit account'),
		});
	}

	return (
		<>
			<GenerateHeader title={t('Account')} breadcrumb={breadcrumb} />
			{apiStatus}
			{!apiStatus && apiData && validateAccess(null, 'superadmin') ? (
				<AccountForm data={apiData} backButton={userId ? '/Users' : ''} />
			) : (
				''
			)}
		</>
	);
}

export default AccountEdit;
