import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCookbookApi } from '../../Components/CookbookApi';
import UseFetchPost, {
	useCookbookApiSet,
} from '../../Components/CookbookApi/useCookbookApiSet';
import ImagePreview from '../../Components/imageSlider/ImageSlider';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import RecipesShowIngredients from './components/RecipesShowIngredients';
import RecipesShowSteps from './components/RecipesShowSteps';
import RecipesToolBox from './components/RecipesShowToolBox';

export default function Recipe() {
	const { recipeId } = useParams();

	const { t } = useTranslation();

	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Recipes/' + recipeId,
		translate: t,
	});

	/*const { data, isPending, errorMessage } = useFetchGet('Recipes/' + recipeId);*/

	const [recipeData, setRecipeData] = useState(null);
	const [persons, setPersons] = useState(3);
	const [render, setRender] = useState(0);
	const [postViewed, setPostViewed] = useState(false);
	const [mobileModePage, setMobileModePage] = useState('content');

	useEffect(() => {
		if (apiData != null && apiData != null) {
			setRecipeData(apiData.data);
			setRender(render + 1);
			postViewInitFn();
		}
	}, [apiData]);

	const postViewInitFn = function () {
		if (!postViewed && apiData && apiData.data.id > 0) {
			setPostViewed(true);
			setTimeout(() => {
				PostViewedFn(apiData.data.id);
			}, 10000);
		}
	};

	const ToggleFavorite = async function (e, favorite) {
		e.preventDefault();
		recipeData.favorite = favorite;

		setRecipeData(recipeData);
		setRender(render + 1);

		await useCookbookApiSet({
			url: 'RecipesFavorite/' + recipeData.id,
			translate: t,
			body: {
				favorite: favorite,
			},
			method: 'PATCH',
		});

		await UseFetchPost('RecipesFavorite/' + recipeData.id, {
			favorite: favorite,
		});

		return false;
	};

	const ToggleLike = async function (e, likes) {
		e.preventDefault();
		recipeData.likes = likes;

		e.target.parentNode.classList.add('lock-down');
		setTimeout(function () {
			e.target.parentNode.classList.remove('lock-down');
		}, 2000);

		setRecipeData(recipeData);
		setRender(render + 1);

		await useCookbookApiSet({
			url: 'RecipesLike/' + recipeData.id,
			translate: t,
			body: {
				likes: likes,
			},
			method: 'POST',
		});

		return false;
	};

	const PostViewedFn = async function (id) {
		await useCookbookApiSet({
			url: 'RecipesViewed/' + id,
			translate: t,
			method: 'POST',
		});
	};
	var breadcrumb = [{ link: '/Recipes', name: t('Recipes') }];

	if (recipeData && recipeData.name) {
		breadcrumb.push({
			name: recipeData.name,
		});
	}
	return (
		<>
			{apiStatus}
			<div className="main-recipe">
				<div className="main-recipe-inner">
					{recipeData && recipeData.images.length > 0 && (
						<div className="recipe-images">
							<ImagePreview images={recipeData.images} />
						</div>
					)}
					<div
						className={
							recipeData && recipeData.images.length > 0
								? 'recipe-content'
								: 'recipe-content full-width'
						}
					>
						{recipeData && (
							<GenerateHeader
								title={recipeData && recipeData.name}
								breadcrumb={breadcrumb}
							/>
						)}
						{recipeData && (
							<div className="container-boxes-small">
								<RecipesToolBox
									recipeData={recipeData}
									persons={persons}
									setPersons={setPersons}
									toggleFavorite={ToggleFavorite}
									toggleLike={ToggleLike}
								/>
							</div>
						)}

						<div className="container-boxes-small">
							{recipeData && recipeData.description}
						</div>
						<div className="container-boxes">
							{recipeData && recipeData.tip && (
								<div className="box-content with-icon container-boxes">
									<i className="fa-solid fa-circle-info"></i>
									<div className="box-content-inner">{recipeData.tip}</div>
								</div>
							)}
						</div>

						<div className="row display-block margin-0">
							<div className="mobile-selector-items">
								<div
									onClick={() => setMobileModePage('content')}
									className={
										mobileModePage === 'content'
											? 'mobile-selector-item active'
											: 'mobile-selector-item'
									}
								>
									<i className="fa-solid fa-list"></i>
								</div>
								<div
									onClick={() => setMobileModePage('ingredients')}
									className={
										mobileModePage === 'ingredients'
											? 'mobile-selector-item active'
											: 'mobile-selector-item'
									}
								>
									<i className="fa-solid fa-crate-apple"></i>
								</div>

								<div
									onClick={() => (window.location.href = '/recipes')}
									className="mobile-selector-item mt-5"
								>
									<i className="fa-solid fa-chevron-left"></i>
								</div>
							</div>
							<div
								className={
									mobileModePage === 'ingredients'
										? 'col-right-400  float-right recipe-ingredients-data active'
										: 'col-right-400  float-right recipe-ingredients-data'
								}
							>
								<div className="container-boxes">
									{recipeData && (
										<>
											<h3>Ingredients</h3>
											<RecipesShowIngredients
												quantity={recipeData.quantity}
												calculateTo={persons}
												list={recipeData.ingredients}
											/>
										</>
									)}
								</div>
							</div>
							<div
								className={
									mobileModePage === 'content'
										? 'col-left-100-400 col-padding-right-50 float-left recipe-content-data active'
										: 'col-left-100-400 col-padding-right-50 float-left recipe-content-data '
								}
							>
								<div className="container-boxes">
									{recipeData && (
										<>
											<h3>{t('Recipe')}</h3>

											<RecipesShowSteps list={recipeData.steps} />
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
