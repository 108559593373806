import React from 'react';

export default function TextAreaField(prop) {
	return (
		<div className="form-floating mb-4">
			<textarea
				type="text"
				className={'form-control'}
				style={{ height: 140 }}
				name={prop.name ? prop.name : ''}
				value={prop.value ? prop.value : ''}
				onChange={prop.onChange ? prop.onChange : ''}
				placeholder={prop.title ? prop.title : ''}
			></textarea>
			<label>{prop.title ? prop.title : ''}</label>
		</div>
	);
}
