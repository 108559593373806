import React, { useState } from 'react';
import { convertImageUrl } from '../CookbookApi';

export default function ImageSlider(prom) {
	const images = prom.images;
	const [currentImage, setCurrentImage] = useState(0);
	const [fullscreen, setFullscreen] = useState(false);

	const toggleFullscreen = function () {
		setFullscreen(!fullscreen);
	};

	const imageSliderGoTo = function (goto) {
		var getGotoImage = goto;
		if (getGotoImage > images.length - 1) {
			getGotoImage = 0;
		}
		if (getGotoImage < 0) {
			getGotoImage = images.length - 1;
		}

		setCurrentImage(getGotoImage);
	};

	const imageSliderNext = function () {
		var getCurrentImage = currentImage;
		getCurrentImage += 1;
		if (getCurrentImage > images.length - 1) {
			getCurrentImage = 0;
		}

		setCurrentImage(getCurrentImage);
	};
	const imageSliderPrev = function () {
		var getCurrentImage = currentImage;
		getCurrentImage -= 1;
		if (getCurrentImage < 0) {
			getCurrentImage = images.length - 1;
		}

		setCurrentImage(getCurrentImage);
	};

	return (
		<>
			{images && (
				<div
					className={
						fullscreen ? 'imagePreviewer fullscreen' : 'imagePreviewer'
					}
				>
					<div className="image-preview-buttons">
						<div className="prev-btn" onClick={() => imageSliderPrev()}>
							<i className="fa fa-angle-left"></i>
						</div>
						<div className="next-btn" onClick={() => imageSliderNext()}>
							<i className="fa fa-angle-right"></i>
						</div>

						<div className="fullscreen-btn" onClick={() => toggleFullscreen()}>
							{fullscreen ? (
								<i className="fa fa-minimize"></i>
							) : (
								<i className="fa fa-maximize"></i>
							)}
						</div>
						<div className="indicator">
							{images.map((item, index) => {
								return (
									<div
										onClick={() => imageSliderGoTo(index)}
										className={
											currentImage === index
												? 'indicator-item active'
												: 'indicator-item'
										}
										key={index}
									>
										<i className="fa fa-circle"></i>
									</div>
								);
							})}
						</div>
					</div>
					<div className="image-preview-items">
						{images.map((item, index) => {
							return (
								<div
									key={index}
									className={
										currentImage === index
											? 'image-preview-item active'
											: 'image-preview-item'
									}
									style={{
										backgroundImage: `url(${convertImageUrl(item, 1920)})`,
									}}
								></div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
}
