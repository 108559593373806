import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';

export default function Header() {
	return (
		<>
			<Navbar />
		</>
	);
}
