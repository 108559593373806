import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCookbookApi } from '../../Components/CookbookApi';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import { validateAccess } from '../../Components/User/User';
import IngredientForm from './components/IngredientForm';

export default function IngredientEdit() {
	const { ingredientsId } = useParams();
	const [dataResult, setResultData] = useState(null);
	const { t } = useTranslation();

	const { apiData } = new useCookbookApi({
		url: 'Ingredients/' + ingredientsId,
		translate: t,
	});

	useEffect(() => {
		if (apiData != null && apiData.data != null) {
			setResultData(apiData);
		}
	}, [apiData]);

	var breadcrumb = [{ link: '/Ingredients', name: t('Ingredients') }];
	if (dataResult && dataResult.data.name) {
		breadcrumb.push({
			link: '/Ingredients/' + dataResult.data.id,
			name: dataResult.data.name,
		});

		breadcrumb.push({
			name: t('Edit ingredient'),
		});
	}

	return (
		<>
			<GenerateHeader title={t('Edit ingredient')} breadcrumb={breadcrumb} />
			{dataResult ? (
				validateAccess(dataResult.data.create_by) ? (
					<IngredientForm dataResult={dataResult} />
				) : (
					'No access'
				)
			) : (
				''
			)}
		</>
	);
}
