import { t } from 'i18next';
import React from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

export default function SelectField(prop) {
	const animatedComponents = makeAnimated();
	const isMulti = prop.isMulti ? prop.isMulti : false;
	var varSelect = [];
	var varOptions = [];
	var varOptionsGroups = {};
	const { Option, SingleValue, MultiValue } = components;

	var useGroups = false;

	const IconOption = (props) => (
		<Option {...props}>
			{props.data.icon && props.data.icon !== '' && (
				<i
					style={{ marginRight: 10 }}
					className={props.data.icon}
					alt={props.data.label}
				/>
			)}
			{props.data.label}
		</Option>
	);

	const SingleValueObject = (props) => (
		<SingleValue {...props}>
			{props.data.icon && props.data.icon !== '' && (
				<i
					style={{ marginRight: 10 }}
					className={props.data.icon}
					alt={props.data.label}
				/>
			)}
			{props.data.label}
		</SingleValue>
	);

	const MultiValueObject = (props) => (
		<MultiValue {...props}>
			{props.data.icon && props.data.icon !== '' && (
				<i
					style={{ marginRight: 10 }}
					className={props.data.icon}
					alt={props.data.label}
				/>
			)}
			{props.data.label}
		</MultiValue>
	);

	if (prop.options) {
		prop.options.map((item, index) => {
			var label_text = t(item.name);

			if (prop.extraLabel && item[prop.extraLabel]) {
				label_text += ' (' + t(item[prop.extraLabel]) + ')';
			}

			if (isMulti && prop.value && prop.value.some((val) => val === item.id)) {
				varSelect.push({
					value: item.id,
					label: label_text,
					icon: item.icon ? item.icon : '',
				});
			} else if (!isMulti && prop.value && prop.value === item.id) {
				varSelect.push({
					value: item.id,
					label: label_text,
					icon: item.icon ? item.icon : '',
				});
			}

			if (item.group_name && item.group_name !== '') {
				useGroups = true;

				if (!varOptionsGroups[item.group_name]) {
					varOptionsGroups[item.group_name] = [];
				}
				varOptionsGroups[item.group_name].push({
					value: item.id,
					label: label_text,
					icon: item.icon ? item.icon : '',
				});
			} else {
				varOptions.push({
					value: item.id,
					label: label_text,
					icon: item.icon ? item.icon : '',
				});
			}

			return '';
		});
	}

	if (varOptionsGroups && useGroups) {
		var groupedOptions = [];

		Object.keys(varOptionsGroups).map(function (key) {
			groupedOptions.push({
				label: key,
				options: varOptionsGroups[key].sort((a, b) =>
					a.label > b.label ? 1 : -1
				),
			});
			return '';
		});

		if (varOptions && varOptions.length > 0) {
			groupedOptions.push({
				label: '',
				options: varOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
			});
		}
		varOptions = groupedOptions;
	} else {
		varOptions = varOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
	}

	function handleSelect(e) {
		var returnObject = {};
		var returnVariable = [];
		returnObject['target'] = {};
		returnObject['target']['name'] = prop.name;

		if (isMulti) {
			e.map((item, index) => {
				returnVariable.push(item.value);
				return '';
			});
			returnObject['target']['value'] = returnVariable;
		} else {
			returnObject['target']['value'] = e && e.value && e.value;
		}

		returnObject.preventDefault = function () {};

		prop.onChange(returnObject);
	}

	return (
		<div
			className={
				isMulti
					? 'form-floating floating-select multi mb-4 '
					: 'form-floating floating-select mb-4 '
			}
		>
			<Select
				closeMenuOnSelect={true}
				components={{
					Option: IconOption,
					animatedComponents: animatedComponents,
					SingleValue: SingleValueObject,
					MultiValue: MultiValueObject,
				}}
				name={prop.name ? prop.name : ''}
				id={prop.name ? prop.name : ''}
				isMulti={isMulti}
				options={varOptions}
				value={varSelect}
				className={
					prop.className ? prop.className + ' form-select' : 'form-select'
				}
				onChange={handleSelect}
			/>
			<label>{prop.title ? prop.title : ''}</label>
		</div>
	);
}
