import { t } from 'i18next';
import $ from 'jquery';
import React from 'react';

export default function RecipesShowIngredients(prom) {
	const list = prom.list;
	var quantity = prom.quantity;

	if (quantity || quantity === undefined) {
		quantity = 1;
	}

	if (quantity < 1) {
		quantity = 1;
	}

	const calculateTo = prom.calculateTo;

	const showElement = function (showElement) {
		console.log(showElement);
		if ($(showElement).hasClass('found')) {
			$(showElement).addClass('used');
			$(showElement).removeClass('found');
		} else if ($(showElement).hasClass('used')) {
			$(showElement).removeClass('used');
		} else {
			$(showElement).addClass('found');
		}
	};

	const ReCalibrateAmount = function (number) {
		var find_one_element = number / quantity;
		var calculated = find_one_element * calculateTo;

		if (calculated > 0) {
			calculated = Math.floor(calculated * 100) / 100;
		}

		return calculated;
	};
	const Capitalize = function (str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<ul className="recipes-ingredients">
			{list &&
				list.map((item, index) => {
					return (
						<li
							className={
								item && item.type === 'headline'
									? 'recipes-ingredients-item-headline h5'
									: 'recipes-ingredients-item cursor-pointer'
							}
							alt={
								item && item.type !== 'headline'
									? t(
											'Click to mark found ingredients, click again when used, third click remove marking'
									  )
									: ''
							}
							title={
								item && item.type !== 'headline'
									? t(
											'Click to mark found ingredients, click again when used, third click remove marking'
									  )
									: ''
							}
							onClick={
								item && item.type !== 'headline'
									? (element) => showElement(element.currentTarget)
									: ''
							}
							key={'li' + index}
						>
							{item && item.type === 'headline' ? (
								t(item.name)
							) : (
								<div className="recipes-ingredients-item-text">
									<div className="recipes-ingredients-item-top">
										<span className="recipes-ingredients-item-quantay">
											{item.quantay > 0 ? (
												parseFloat(ReCalibrateAmount(item.quantay)) +
												' ' +
												(item.indication ? item.indication : '')
											) : (
												<small>
													{item.indication ? t(item.indication) : ''}
												</small>
											)}
										</span>
										<span className="recipes-ingredients-item-name">
											<i
												className={
													'recipes-svg duel_icon_color ' + item.indication_icon
												}
											/>
											{item.indication_name && t(item.indication_name)}
										</span>
									</div>
									<div className="recipes-ingredients-item-bottom">
										{Capitalize(item.description)}
									</div>
								</div>
							)}
						</li>
					);
				})}
		</ul>
	);
}
