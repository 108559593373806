import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReplaceWithBr, useCookbookApi } from '../../Components/CookbookApi';
import ImagePreview from '../../Components/imageSlider/ImageSlider';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import Recipes from '../Recepes/Recipes';
import ToolBox from './components/ToolBox';

export default function Ingredient() {
	const { ingredientsId } = useParams();

	const { t } = useTranslation();

	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Ingredients/' + ingredientsId,
		translate: t,
	});

	const [recipeData, setRecipeData] = useState(null);
	const [render, setRender] = useState(0);

	useEffect(() => {
		if (apiData != null && apiData != null) {
			setRecipeData(apiData.data);
			setRender(render + 1);
		}
	}, [apiData]);

	var breadcrumb = [{ link: '/Ingredients', name: t('Ingredients') }];

	if (recipeData && recipeData.name) {
		breadcrumb.push({
			name: t(recipeData.name),
		});
	}

	return (
		<>
			{apiStatus}
			<div className="main-ingredient">
				<div className="main-ingredient-inner">
					{recipeData && recipeData.images.length > 0 && (
						<div className="recipe-images">
							<ImagePreview images={recipeData.images} />
						</div>
					)}
					<div
						className={
							recipeData && recipeData.images.length > 0
								? 'recipe-content'
								: 'recipe-content full-width'
						}
					>
						{recipeData && (
							<GenerateHeader
								title={recipeData && t(recipeData.name)}
								breadcrumb={breadcrumb}
							/>
						)}
						{recipeData && (
							<div className="container-boxes-small">
								<ToolBox recipeData={recipeData} />
							</div>
						)}

						<div className="container-boxes-small">
							{recipeData && (
								<p
									dangerouslySetInnerHTML={{
										__html: ReplaceWithBr(recipeData.description),
									}}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="clear"></div>
			<div>
				<Recipes
					find_by_ingredient={ingredientsId}
					noBreadcrub={true}
					titleFormat={'h4'}
					title={
						t('Recipes that contain') +
						' ' +
						(recipeData ? t(recipeData.name) : '')
					}
				/>
			</div>
		</>
	);
}
