import React from 'react';

export default function InputField(prop) {
	var varInputMode = 'text';
	var type = 'text';
	if (prop.type && prop.type === 'number') {
		varInputMode = 'numeric';
		type = 'text';
	} else if (prop.type) {
		type = prop.type;
	}

	const attr = {};

	if (prop.required && prop.required > 0) {
		attr.required = 'required';
	}

	return (
		<div className={prop.noMargin ? 'form-floating' : 'form-floating mb-4'}>
			<input
				type={type}
				inputMode={varInputMode}
				className={prop.class ? 'form-control ' + prop.class : 'form-control'}
				{...attr}
				placeholder={prop.placeholder ? prop.placeholder : prop.title}
				name={prop.name ? prop.name : ''}
				value={prop.value ? prop.value : ''}
				onChange={prop.onChange ? prop.onChange : ''}
			/>
			<label>{prop.title ? prop.title : ''}</label>
		</div>
	);
}
