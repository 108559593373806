import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { convertImageUrl } from '../CookbookApi';

export const ItemsList = (props) => {
	const items = props.items;
	var elements = props.elements;
	var link = props.link;
	return (
		<>
			<table className="list-items">
				<thead>
					<tr>
						{Object.keys(elements).map((key) => (
							<th className={key} key={key}>
								{elements[key]}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{items.map((item, index) => {
						return (
							<tr key={item.id}>
								{Object.keys(elements).map((key) => (
									<td key={key + index} className={key + ' ' + 'list-item'}>
										<Link
											className={link ? 'clickable' : ''}
											to={
												link &&
												window.location.href +
													'/' +
													item.id +
													(props.linkend ? props.linkend : '')
											}
										>
											{(key === 'images' || key === 'image') &&
											item[key] !== undefined &&
											item[key][0] !== undefined ? (
												<div
													className="list-key-image"
													style={{
														backgroundImage: `url(${convertImageUrl(
															item[key][0],
															500
														)})`,
													}}
												></div>
											) : key === 'images' || key === 'image' ? (
												<div className="list-key-image default-image">
													<i className="fa-duotone fa-image"></i>
												</div>
											) : key === 'first_name_last_name' ? (
												<div className={'list-key-' + key}>
													{item['first_name'] + ' ' + item['last_name']}
												</div>
											) : key === 'tools' ? (
												<div className={'list-key-' + key}>
													<i className="fa-duotone fa-pen"></i>
												</div>
											) : key === 'name-description' ? (
												<>
													<div className="list-key-name-description-name">
														{t(item['name'])}
													</div>
													<div className="list-key-name-description-description">
														{t(item['description'])}
													</div>
												</>
											) : item[key] !== '' && key === 'description' ? (
												<>
													<div className="card-key-description">
														{item.description.length > 200
															? `${item.description.substring(0, 200)}...`
															: item.description}
													</div>
												</>
											) : item[key] !== '' && key === 'icon-name' ? (
												<>
													<div className={'card-key-' + key}>
														{item['icon'] ? (
															<i className={item['icon'] + ' mr-10'}></i>
														) : (
															''
														)}
														{t(item['name'])}
													</div>
												</>
											) : (
												<div className={'list-key-' + key}>{t(item[key])}</div>
											)}
										</Link>
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
