import { t } from 'i18next';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import { InputField, TextAreaField } from '../../../Components/Fields';

export default function RecipesEditStepsV2(props) {
	const name = props.name ? props.name : 'default';
	const value = props.value ? props.value : [];

	const stepChangeHandler = (event, index, type) => {
		event.preventDefault();
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData[index][event.target.name] = event.target.value;
		stepData[index]['type'] = type;
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	const stepRemoveHandler = (event, index) => {
		event.preventDefault();
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData.splice(index, 1);
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	const stepAddHandler = (event, type) => {
		event.preventDefault();
		var dataArray = value[0];

		if (!dataArray[name]) {
			dataArray[name] = [];
		}
		var stepData = [...dataArray[name]];
		stepData.push({ text: '', pos: 0, id: 0, type: type });
		value[0][name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	/*const handleSort = () => {
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(dragItem, 1)[0];

		stepData.splice(dragOverItem, 0, draggedItemContent);

		dragItem = null;
		dragOverItem = null;
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};*/

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(result.source.index, 1)[0];

		stepData.splice(result.destination.index, 0, draggedItemContent);

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);

		/*
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData = reorder(stepData, result.source.index, result.destination.index);

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);*/
	};

	return (
		<div className="recipes-edit-steps">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<ul
							className="sort-drag-drop"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{value[0] &&
								value[0][name] &&
								value[0][name].map((item, index) => (
									<Draggable
										key={index}
										draggableId={index.toString()}
										index={index}
									>
										{(provided, snapshot) => (
											<li
												className={
													(item && item.type === 'headline'
														? 'recipes-step-item-headline'
														: 'recipes-step-item') +
													(snapshot.isDragging ? ' isDragging' : '')
												}
												ref={provided.innerRef}
												{...provided.draggableProps}
												style={provided.draggableProps.style}
												/*onDragStart={(e) => {
												dragItem = index;
											}}
											onDragEnter={(e) => {
												dragOverItem = index;
											}}
											onDragEnd={handleSort}
											onDragOver={(e) => {
												e.preventDefault();
											}}*/
											>
												<div className="input-group">
													<div
														{...provided.dragHandleProps}
														className="sort-bar-list"
													>
														<i className="fa-solid fa-bars"></i>
													</div>
													{item && item.type === 'headline' ? (
														<InputField
															name="text"
															onChange={(event) =>
																stepChangeHandler(event, index, 'headline')
															}
															value={item.text}
															title={t('Headline')}
														/>
													) : (
														<TextAreaField
															title={'Step ' + (index + 1)}
															name={'text'}
															value={item.text}
															noMargin={false}
															onChange={(event) =>
																stepChangeHandler(event, index, 'step')
															}
														/>
													)}
													<Button
														onClick={(event) => stepRemoveHandler(event, index)}
													>
														<i className="fa-solid fa-trash"></i>
													</Button>
												</div>
											</li>
										)}
									</Draggable>
								))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
			<Button
				style={{ marginRight: 20 }}
				onClick={(event) => stepAddHandler(event, 'step')}
			>
				{t('Add step')}
			</Button>
			<Button
				variant="secondary"
				size="sm"
				onClick={(event) => stepAddHandler(event, 'headline')}
			>
				{t('Add headline')}
			</Button>
		</div>
	);
}
