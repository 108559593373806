import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemsCard } from './ItemsCard';
import { ItemsList } from './ItemsList';

export const Items = (props) => {
	const items = props.items;
	const title = props.title;
	var elements = props.elements;
	var link = props.link;
	var linkend = props.linkend;
	var defaultText = props.defaultText;
	var showSelector = props.showSelector;

	if (elements === undefined) {
		elements = { 'name-description': 'Name / Description' };
	}

	const { t } = useTranslation();

	const [view, setView] = useState(
		props.view !== undefined ? props.view : 'card'
	);

	return (
		<>
			{items && items.length > 0 ? (
				<div className="component-box">
					{title && <h2>{title}</h2>}
					{showSelector && (
						<>
							<div className="item-filter-tools">
								<div className="item-filter-item">
									<select
										value={view}
										onChange={(e) => setView(e.target.value)}
									>
										<option value={'list'}>{t('List')}</option>
										<option value={'card'}>{t('Card')}</option>
									</select>
								</div>
							</div>
						</>
					)}

					{view === 'list' ? (
						<ItemsList
							title={title}
							elements={elements}
							items={items}
							link={link}
							linkend={linkend}
						/>
					) : (
						<ItemsCard
							title={title}
							elements={elements}
							items={items}
							link={link}
							linkend={linkend}
						/>
					)}
				</div>
			) : (
				<>{defaultText && defaultText}</>
			)}
		</>
	);
};
