/*import i18n from 'i18next';
import LanguageDetector from LanguageDetector;


import { initReactI18next } from initReactI18next;
import I18nextBrowserLanguageDetector from I18nextBrowserLanguageDetector;

import { initReactI18next } from 'react-i18next';
*/
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
	en: {
		translation: {
			paragraph:
				'react-i18next is a powerful internationalization framework for React / React Native which is based on i18next.',
		},
	},
	da: {
		translation: {
			Recipes: 'Opskrifter',
			Recipe: 'Opskrift',
			'Create recipe': 'Opret opskrift',
			'Create new recipe': 'Opret ny opskrift',
			'Edit recipe': 'Rediger opskrift',
			Account: 'Konto',
			Home: 'Hjem',
			'My favorites': 'Mine favoritter',
			'All recipes': 'Alle opskrifter',
			List: 'Liste',
			Card: 'Kort',
			Image: 'Billede',
			Name: 'Navn',
			Category: 'Kategori',
			Categories: 'Kategorier',
			Logout: 'Log ud',
			'Kcal pr persons': 'Kcal pr personer',
			'Change language to Danish': 'Skift sprog til Dansk',
			'Change language to English': 'Skift sprog til Engelsk',
			Viewed: 'Set',
			'Create by': 'Oprettet af',
			'Cooking time': 'Madlavningstid',
			Hour: 'Time',
			Hours: 'Timer',
			Minutes: 'Minutter',
			Minute: 'Minut',
			'Prep time': 'Forberedelsestid',
			Person: 'Person',
			Persons: 'Personer',
			Like: 'Synes godt om',
			'Change like': 'Skift synes godt om',
			'Remove like': 'Fjern synes godt om',
			'Add to favorites': 'Tilføj til favoritter',
			'Remove to favorites': 'Fjern fra favoritter',
			Title: 'Titel',
			Description: 'Beskrivelse',
			Tip: 'Tip',
			'Number of potions': 'Antal potioner',
			'Are you sure?': 'Er du sikker?',
			Yes: 'Ja',
			No: 'Nej',
			Save: 'Gem',
			Back: 'Tilbage',
			files: 'Filer',
			'You can only add maximum of': 'Du kan kun tilføje maksimalt',
			'Wrong file type only images': 'Forkert file type kun billeder',
			Delete: 'Slet',
			'Are you sure want to delete this recipe.':
				'Er du sikker på du vil slette opskriften',
			Technician: 'Tekniker',
			Spices: 'Krydderier',
			'Manage account': 'Administrer konto',
			'Connection error, try again later':
				'Forbindelses fejl. Prøv igen senere',
			Loading: 'Indlæser',

			Lemon: 'Citron',
			Strawberry: 'Jordbær',
			Tomatoes: 'Tomater',
			Pumpkin: 'Græskar',
			Pineapple: 'Ananas',
			Pear: 'Pære',
			Peach: 'Fersken',
			Mango: 'Mango',
			Kiwi: 'Kiwi',
			Grapes: 'Vindruer',
			Coconut: 'Kokosnød',
			Cherries: 'Kirsebær',
			Banana: 'Banan',
			Blueberries: 'Blåbær',
			Apple: 'Æble',
			Watermelon: 'Vandmelon',
			Orange: 'Applesin',
			Olive: 'Oliven',
			'Star fruit': 'Stjernefrugt',
			'Star apple': 'Stjerne æble',
			Raspberry: 'Hindbær',
			Pomegranate: 'Granatæble',
			Passionfruit: 'Passionsfrugt',
			'Blood orange': 'Blodappelsin',
			Clementine: 'Clementine',
			Mandarine: 'Mandarin',
			Nectarine: 'Nektarin',
			Cantaloupe: 'Cantaloupe',
			'Galia melon': 'Galia melon',
			Lime: 'Lime',
			Grapefruit: 'Grapefruit',
			Raisin: 'Rosin',
			Elderberry: 'Hyldebær',
			Dragonfruit: 'Dragefrugt',
			Cranberry: 'Tranebær',
			Boysenberry: 'Boysenbær',
			Blackberry: 'Brombær',
			Apricot: 'Abrikos',
			Chili: 'Chili',
			Garlic: 'Hvidløg',
			Carrots: 'Gulerødder',
			Potato: 'Kartoffel',
			Onion: 'Løg',
			'Bell pepper': 'Peberfrugt',
			Pea: 'Ærter',
			Mushroom: 'Champignon',
			'Leafy green': 'Bladgrøn',
			Eggplant: 'Aubergine',
			Cucumber: 'Agurk',
			Avocado: 'Avocado',
			Broccoli: 'Broccoli',
			Corn: 'Majs',
			Salat: 'Salat',
			Artichoke: 'Artiskok',
			Asparagus: 'Asparges',
			'Brussels sprouts': 'Rosenkål',
			Fennel: 'Fennikel',
			Spinach: 'Spinat',
			'Mustard greens': 'Sennepsgrønt',
			'Kidney beans': 'Kidneybønner',
			Lentils: 'Linser',
			Peanuts: 'Jordnødder',
			'Split peas': 'Flækkede ærter',
			Leek: 'Porre',
			Shallot: 'Skalotteløg',
			Jalapeño: 'Jalapeño',
			Beetroot: 'Rødbede',
			Celeriac: 'Knoldselleri',
			Ginger: 'Ingefær',
			Parsnip: 'Pastinak',
			Radish: 'Radise',
			'Sweet potato': 'Sød kartoffel',
			Turnip: 'Majroe',
			Sweetcorn: 'Sukkermajs',
			Squash: 'Squash',
			'Butternut squash': 'Butternut squash',
			'Snap peas': 'Snap ærter',
			Bacon: 'Bacon',
			Sausage: 'Pølser',
			Shrimp: 'Rejer',
			Squid: 'Blæksprutter',
			Lobster: 'Hummer',
			Crab: 'Krabber',
			Fish: 'Fisk',
			Chicken: 'Kylling',
			Meat: 'Kød',
			Egg: 'Æg',
			Pork: 'Svinekød',
			Ham: 'Skinke',
			Prosciutto: 'Prosciutto',
			Salami: 'Salami',
			Beef: 'Bøf',
			'Lamb ': 'Lam',
			Fårekød: 'Fårekød',
			Turkey: 'Kalkun',
			Venison: 'Vildtkød',
			Duck: 'And',
			'Wild Boar': 'Vildsvin',
			Bison: 'Bison',
			Goose: 'Gås',
			Rabbit: 'Kanin',
			Pheasant: 'Fasan',
			Caviar: 'Kaviar',
			Carp: 'Karpe',
			Catfish: 'Havkat',
			Clam: 'Musling',
			Deer: 'Hjort',
			Eel: 'Ål',
			Heart: 'Hjerte',
			Herring: 'Sild',
			Kidney: 'Nyre',
			Liver: 'Lever',
			Mussels: 'Muslinger',
			Octopus: 'Blæksprutte',
			Quail: 'Vagtel',
			Salmon: 'Laks',
			Scallops: 'Kammuslinger',
			'Sea bass': 'Havaborre',
			Trout: 'ørred',
			Tuna: 'Tun',
			Cod: 'Torsk',
			Gram: 'Gram',
			Letter: 'Brev',
			Can: 'Dåse',
			Deciliters: 'Deciliter',
			Box: 'Bokse',
			Kilogram: 'Kilogram',
			Milliliters: 'Milliliter',
			'Table spoon': 'Spiseske',
			Teaspoon: 'Teske',
			Cubes: 'Terninger',
			Bottle: 'Flaske',
			Jar: 'Krukke',
			Chestnut: 'Kastanje',
			'French fries': 'Pommes frites',
			Flatbread: 'Fladbrød',
			Rice: 'Ris',
			Bone: 'Ben',
			Bagel: 'Bagel',
			Honey: 'Honning',
			Bread: 'Brød',
			Coffee: 'Kaffe',
			Chocolate: 'Chokolade',
			Cheese: 'Ost',
			Butter: 'Smør',
			Pasta: 'Pasta',
			'Tomato paste': 'Tomatpuré',
			'Chicken Broth': 'Kyllinge bouillon',
			'Beef Broth': 'Okse Bouillon',
			'Vegetable  Broth': 'Grønsage Bouillon',
			Broth: 'Bouillon',
			'Soy sauce': 'Soja sovs',
			Ketchup: 'Ketchup',
			Remoulade: 'Remoulade',
			Mayonnaise: 'Mayonnaise',
			'Canned Mushrooms': 'Dåse svampe',
			'Canned beans': 'Dåse bønner',
			'Canned corn': 'Dåse majs',
			'Peanut Butter': 'Jordnøddesmør',
			'Baking powder': 'Bagepulver',
			'Corn starch': 'Majsstivelse',
			'White Sugar': 'Sukker',
			'Brown Sugar': 'Brunt sukker',
			Flour: 'Mel',
			'Sour cream': 'Creme Fraiche',
			Feta: 'Feta',
			Cheddarost: 'Cheddarost',
			'Cottage cheese': 'Hytteost',
			Parmesan: 'Parmesan',
			Mozzarella: 'Mozzarella',
			'Cream cheese': 'Flødeost',
			'All spice': 'Allehånde',
			Anise: 'Anis',
			Basil: 'Basilikum',
			Birches: 'Birkes',
			Buckhorns: 'Bukkehorns',
			Cayenne: 'Cayenne',
			'Cayenne pepper': 'Cayennepeber',
			'Lemon balm': 'Citronmelisse',
			Dill: 'Dild',
			Juniper: 'Enebær',
			Tarragon: 'Estragon',
			'Fennel seeds': 'Fennikel frø',
			Turmeric: 'Gurkemeje',
			Hyssop: 'Isop',
			Cinnamon: 'Kanel',
			Capers: 'Kapers',
			Cardamom: 'Kardemomme',
			Curry: 'Karry',
			Kommen: 'Kommen',
			Herbs: 'Krydderurter',
			Chervil: 'Kørvel',
			'Bay leaves': 'Laurbørblade',
			'Leaf stick': 'Løvstikke',
			Marjoram: 'Merian',
			Nutmeg: 'Muskatnød',
			Mint: 'Mynte',
			Cloves: 'Nelliker',
			Oregano: 'Oregano',
			Paprika: 'Paprika',
			horseradish: 'Peberrod',
			Parsley: 'Persille',
			Chives: 'Purløg',
			Rosemary: 'Rosmarin',
			Saffron: 'Safran',
			Wound: 'Sar',
			Sage: 'Salvie',
			Celery: 'Selleri',
			'Celery salt': 'Sellerisalt',
			Mustard: 'Sennep',
			'Sesame seeds': 'Sesamfrø',
			Thyme: 'Timian',
			Vanilla: 'Vanilje',
			Cilantro: 'Koriander',
			'Ground beef': 'Hakket oksekød',
			'Minced chicken': 'Hakket kyllingkød',
			'Minced pork': 'Hakket svinekød',
			'Minced pork/veal': 'Hakket svine/kalvekød',
			Public: 'Offentlig',
			Disabled: 'Deaktiveret',
			Private: 'Privat',
			Develop: 'Udvikle',
			Images: 'Billeder',
			'Drag to upload file': 'Træk for at uploade fil',
			or: 'eller',
			'Upload file': 'Upload fil',
			'Upload files': 'Upload filer',
			'Recipes steps': 'Opskrifters trin',
			'Recipes ingredients': 'Opskrifter ingredienser',
			'Add ingredients': 'Tilføj ingredienser',
			'Add step': 'Tilføj trin',
			Add: 'Tilføj',
			'Add headline': 'Tilføj overskrift',
			Carbohydrates: 'Kulhydrater',
			Fat: 'Fed',
			Protein: 'Protein',
			Quantay: 'Antal',
			Measurement: 'Måling',
			Starter: 'Forret ret',
			'Main course': 'Hovede ret',
			Desert: 'Desert',
			Cake: 'Kage',
			Sauce: 'Sovs',
			Accessories: 'Tilbehør',
			Tips: 'Tips',
			'Wheat flour': 'Hvedemel',
			Grahamflour: 'Grahamsmel',
			'Durum wheat flour': 'Durumhvedemel',
			'Whole wheat flour': 'Fuldkornshvedemel',
			'Rye flour': 'Rugmel',
			Oatmeal: 'Havregryn',
			Yeast: 'Gær',
			Users: 'Bruger',
			'Edit account': 'Rediger bruger',
			'Create new user': 'Opret ny bruger',
			'Account type': 'Konto type',
			'Minced beef': 'Hakkede oksekød',
			Username: 'Brugernavn',
			'First name': 'For navn',
			'Last name': 'Efter navn',
			Phone: 'Mobil',
			Address: 'Adresse',
			Zip: 'Postnummer',
			Sex: 'Køn',
			Female: 'Kvinde',
			Male: 'Mand',
			City: 'By',
			User: 'Bruger',
			Password: 'Adgangskode',
			Milk: 'Mælk',
			Lamb: 'Lam',
			Mutton: 'Får',
			'Vegetable Broth': 'Grønsags bouillon',
			'Glass mushrooms': 'Glas champignons',
		},
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'da',
		fallbackLng: 'sp',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
