import React, { useEffect, useState } from 'react';
import { getLocalStorage } from '../LocalStorage/LocalStorage';
import { logout } from '../User/User';
import { FetchLoader } from './FetchLoader';

export const useCookbookApi = function (communicate) {
	var loadingText = '';
	if (communicate.translate) {
		loadingText = communicate.translate(loadingText);
	}

	const [apiData, setApiData] = useState(null);
	const [apiStatus, setApiStatus] = useState(
		<FetchLoader loadingText={loadingText} isPending={true} />
	);

	if (!communicate) {
		communicate = {};
	}

	const fetchData = async () => {
		var iData = null;
		try {
			const header = { 'Content-Type': 'application/json' };

			const userData = getLocalStorage('user_data');
			if (userData.token[2] !== undefined && userData.token[2] !== '') {
				header.Authorization = 'Basic ' + userData.token[2];
			}

			if (!communicate.method) {
				communicate.method = 'GET';
			}
			if (!communicate.url) {
				communicate.url = '';
			}

			communicate.headers = header;
			if (communicate.body) {
				communicate.body = JSON.stringify(communicate.body);
			}

			var data_feed = await fetch(
				'https://rest.unrealhuman.dk/' + communicate.url,
				communicate
			);
			if (!data_feed.ok) {
				throw Error(
					'Unable to fetch data from server code: ' +
						iData.status +
						' (' +
						iData.statusText +
						')'
				);
			}

			iData = await data_feed.json();
		} catch (err) {
			var errorMessage = 'Connection error, try again later';
			if (communicate.translate) {
				errorMessage = communicate.translate(errorMessage);
			}

			setApiStatus(
				<FetchLoader isPending={false} errorMessage={errorMessage} />
			);
			iData = null;
		}

		if (iData) {
			if (
				iData &&
				iData !== undefined &&
				iData.access !== undefined &&
				iData.access.is_loged_in !== undefined &&
				!iData.access.is_loged_in
			) {
				console.log(iData);
				setApiData(null);

				logout();
				return false;
			}

			setApiData(iData);
			setApiStatus(null);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return { apiData, apiStatus };
};

export default useCookbookApi;
