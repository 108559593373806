import { t } from 'i18next';
import React, { useState } from 'react';

export default function RecipesShowSteps(prom) {
	const list = prom.list;
	const [selected, setSelected] = useState(null);

	const showElement = (index) => {
		if (index === selected) {
			index = 0;
		}
		setSelected(index);
	};

	return (
		<div className="recipes-steps ol">
			{list.map((item, index) => {
				return item.type === 'headline' ? (
					<div key={index} className={'recipes-step li reset headline h5 '}>
						{item.text}
					</div>
				) : (
					<div
						key={index}
						onClick={(element) => showElement(index + 1)}
						alt={t(
							'Click to mark you current position, to remove click on the same place again'
						)}
						title={t(
							'Click to mark you current position, to remove click on the same place again'
						)}
						className={
							'recipes-step li cursor-pointer ' +
							(selected && index + 1 === selected ? 'active' : '')
						}
					>
						{item.text}
					</div>
				);
			})}
		</div>
	);
}
