import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import { validateAccess } from '../../Components/User/User';
import IngredientForm from './components/IngredientForm';

export default function IngredientCreate() {
	const { t } = useTranslation();

	const { apiData } = new useCookbookApi({
		url: 'Ingredients/new',
		translate: t,
	});

	var breadcrumb = [
		{ link: '/Ingredients', name: t('Ingredients') },
		{ name: t('Create new ingredient') },
	];

	return (
		<>
			<GenerateHeader
				title={t('Create new ingredient')}
				breadcrumb={breadcrumb}
			/>
			{apiData ? (
				validateAccess(null, 'admin') ? (
					<IngredientForm dataResult={apiData} />
				) : (
					'No access'
				)
			) : (
				''
			)}
		</>
	);
}
