import { t } from 'i18next';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import { InputField, SelectField } from '../../../Components/Fields';

export default function RecipesEditIngredientsV2(props) {
	const name = props.name ? props.name : 'default';
	const value = props.value ? props.value : [];
	const defaultTypeData = props.defaultTypeData ? props.defaultTypeData : [];

	const ingredientsChangeHandler = (event, index, type) => {
		event.preventDefault();
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData[index][event.target.name] = event.target.value;
		stepData[index]['type'] = type;

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	const ingredientsRemoveHandler = (event, index) => {
		event.preventDefault();
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData.splice(index, 1);
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	const ingredientsAddHandler = (event, index, type) => {
		event.preventDefault();
		var dataArray = value[0];

		if (!dataArray[name]) {
			dataArray[name] = [];
		}

		var stepData = [...dataArray[name]];

		stepData.push({ pos: 0, id: 0, type: type });
		value[0][name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	//var dragItem = 0;
	//var dragOverItem = 0;

	/*const handleSort = () => {
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(dragItem, 1)[0];

		stepData.splice(dragOverItem, 0, draggedItemContent);

		dragItem = null;
		dragOverItem = null;
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};*/

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(result.source.index, 1)[0];

		stepData.splice(result.destination.index, 0, draggedItemContent);

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);

		/*
		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		stepData = reorder(stepData, result.source.index, result.destination.index);

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);*/
	};

	return (
		<div className="recipes-edit-ingredients">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<ul
							className="recipes-ingredients sort-drag-drop"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{value[0][name] &&
								value[0][name].map((item, index) => (
									<Draggable
										key={index}
										draggableId={index.toString()}
										index={index}
									>
										{(provided, snapshot) => (
											<li
												ref={provided.innerRef}
												{...provided.draggableProps}
												style={provided.draggableProps.style}
												className={
													(item && item.type === 'headline'
														? 'recipes-ingredients-item-headline'
														: 'recipes-ingredients-item') +
													(snapshot.isDragging ? ' isDragging' : '')
												}
												/*draggable
												onDragStart={(e) => {
													dragItem = index;
												}}
												onDragEnter={(e) => {
													dragOverItem = index;
												}}
												onDragEnd={handleSort}
												onDragOver={(e) => {
													e.preventDefault();
												}}
												key={'li' + index}*/
											>
												<div className="input-group">
													<div
														{...provided.dragHandleProps}
														className="sort-bar-list"
													>
														<i className="fa-solid fa-bars"></i>
													</div>
													{item && item.type === 'headline' ? (
														<InputField
															name="name"
															onChange={(event) =>
																ingredientsChangeHandler(
																	event,
																	index,
																	'headline'
																)
															}
															value={item.name}
															title={t('Headline')}
														/>
													) : (
														<div className="outerbox form-floating">
															<div className="topbox">
																<SelectField
																	value={item.ingredients_id}
																	isMulti={false}
																	options={
																		defaultTypeData.Ingredients &&
																		defaultTypeData.Ingredients
																	}
																	title={t('Name')}
																	name="ingredients_id"
																	onChange={(event) =>
																		ingredientsChangeHandler(
																			event,
																			index,
																			'ingredient'
																		)
																	}
																/>
															</div>
															<div className="bottombox input-group">
																<InputField
																	name="quantay"
																	onChange={(event) =>
																		ingredientsChangeHandler(
																			event,
																			index,
																			'ingredient'
																		)
																	}
																	type="number"
																	value={item.quantay}
																	title={t('Quantay')}
																/>

																<SelectField
																	value={item.measurement_id}
																	isMulti={false}
																	options={
																		defaultTypeData.Measurement &&
																		defaultTypeData.Measurement
																	}
																	extraLabel={'indication'}
																	title={t('Measurement')}
																	name="measurement_id"
																	onChange={(event) =>
																		ingredientsChangeHandler(
																			event,
																			index,
																			'ingredient'
																		)
																	}
																/>

																<InputField
																	name="description"
																	onChange={(event) =>
																		ingredientsChangeHandler(
																			event,
																			index,
																			'ingredient'
																		)
																	}
																	type="text"
																	value={item.description}
																	title={t('Description')}
																/>
															</div>
														</div>
													)}

													<Button
														onClick={(event) =>
															ingredientsRemoveHandler(event, index)
														}
													>
														<i className="fa-solid fa-trash"></i>
													</Button>
												</div>
											</li>
										)}
									</Draggable>
								))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
			<Button
				style={{ marginRight: 20 }}
				onClick={(event) => ingredientsAddHandler(event, 0, 'ingredient')}
			>
				{t('Add ingredients')}
			</Button>
			<Button
				variant="secondary"
				size="sm"
				onClick={(event) => ingredientsAddHandler(event, 0, 'headline')}
			>
				{t('Add headline')}
			</Button>
		</div>
	);
}
