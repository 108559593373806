import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import { validateAccess } from '../../Components/User/User';
import AccountForm from './components/AccountForm';

function AccountCreate() {
	const { t } = useTranslation();

	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Users/new',
		translate: t,
	});

	var breadcrumb = [
		{ link: '/Users', name: t('Users') },
		{ name: t('Create new user') },
	];

	return (
		<>
			<GenerateHeader title={t('Account')} breadcrumb={breadcrumb} />
			{apiStatus}
			{!apiStatus && apiData && validateAccess(null, 'superadmin') ? (
				<AccountForm data={apiData} backButton={'/Users'} />
			) : (
				''
			)}
		</>
	);
}

export default AccountCreate;
