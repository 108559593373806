import { t } from 'i18next';
import { confirmAlert } from 'react-confirm-alert';
import useCookbookApi from './useCookbookApi';
import useCookbookApiDelete from './useCookbookApiDelete';
import useCookbookApiSet from './useCookbookApiSet';

const HandleChange = (e, dataObject, setDataObject, render, setRender) => {
	if (
		e.target.attributes &&
		e.target.attributes.inputmode &&
		e.target.attributes.inputmode.value === 'numeric'
	) {
		const re = /^[0-9\b]+$/;
		if (e.target.value !== '' && !re.test(e.target.value)) {
			return;
		}
	}

	dataObject[e.target.name] = e.target.value;
	setDataObject(dataObject);
	setRender(render + 1);
};

const HandleSubmit = async (e, action, dataObject, redirect) => {
	e.preventDefault();

	var body = document.body;
	body.classList.add('main-loader');

	const { apiData, errorMessage } = await useCookbookApiSet({
		url: action + (dataObject && dataObject.id > 0 ? '/' + dataObject.id : ''),
		translate: t,
		body: dataObject,
		method: dataObject && dataObject.id > 0 ? 'PATCH' : 'POST',
	});

	var generate_url = '';

	if (dataObject && action) {
		if (dataObject.id > 0) {
			generate_url = '/' + action + '/' + dataObject.id;
		} else {
			generate_url = '/' + action;
		}
	}

	if (redirect) {
		generate_url = redirect;
	}

	var alert = {
		title: t('Saved'),
		message: t(action + ' has been saved'),
		buttons: [
			{
				label: t('Done'),
				className: 'btn btn-primary',
				onClick: () => (window.location.href = generate_url),
			},
		],
	};

	if (apiData && apiData.status && apiData.status === 'error') {
		alert.title = t('Error');
		if (apiData.message) {
			alert.message = apiData.message;
		}

		alert.buttons = [
			{
				label: t('OK'),
				className: 'btn btn-primary',
			},
		];
	} else if (errorMessage) {
		alert.title = t('Error');
		alert.message = errorMessage;
		alert.buttons = [
			{
				label: t('OK'),
				className: 'btn btn-primary',
			},
		];
	}
	body.classList.remove('main-loader');
	confirmAlert(alert);
};

const HandleDelete = (e, dataObject, action, redirect) => {
	e.preventDefault();
	if (dataObject && dataObject.id > 0) {
		confirmAlert({
			title: t('Delete'),
			message: t('Are you sure want to delete this.'),
			buttons: [
				{
					label: t('Yes'),
					className: 'btn btn-primary',
					onClick: () => HandleDeleteAction(dataObject, action, redirect),
				},
				{
					label: t('No'),
					className: 'btn btn-secondary',
				},
			],
		});
	}
};

const HandleDeleteAction = async (dataObject, action, redirect) => {
	var body = document.body;
	body.classList.add('main-loader');

	const { apiData, errorMessage } = await useCookbookApiDelete(
		action + '/' + dataObject.id
	);

	var generate_url = '';
	generate_url = '/' + action;

	if (redirect) {
		generate_url = redirect;
	}

	var alert = {
		title: t('Deleted'),
		message: t(action + ' has been deleted'),
		buttons: [
			{
				label: t('Done'),
				className: 'btn btn-primary',
				onClick: () => (window.location.href = generate_url),
			},
		],
	};

	if (apiData && apiData.status && apiData.status === 'error') {
		alert.title = t('Error');
		if (apiData.message) {
			alert.message = apiData.message;
		}

		alert.buttons = [
			{
				label: t('OK'),
				className: 'btn btn-primary',
			},
		];
	} else if (errorMessage) {
		alert.title = t('Error');
		alert.message = errorMessage;
		alert.buttons = [
			{
				label: t('OK'),
				className: 'btn btn-primary',
			},
		];
	}

	body.classList.remove('main-loader');
	confirmAlert(alert);
};

const convertImageUrl = function (image, size) {
	const imageData = image.split('|');
	if (imageData && imageData.length > 2) {
		if (imageData[0] === '0') {
			return imageData[2];
		} else {
			var imageUrl =
				'https://rest.unrealhuman.dk/image.php?path=' +
				imageData[1] +
				'&filename=' +
				imageData[2];

			if (size && size > 0) {
				imageUrl += '&size=' + size;
			}
		}
		return imageUrl;
	} else {
		return '';
	}
};

const ReplaceWithBr = function (text) {
	return text.replace(/\n/g, '<br />');
};

export {
	useCookbookApiSet,
	useCookbookApi,
	convertImageUrl,
	HandleSubmit,
	HandleChange,
	HandleDelete,
	ReplaceWithBr,
};
