import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi/useCookbookApi';
import { Items } from '../../Components/Items/Items';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import ToolBox from './components/ToolBox';

export default function Ingredients() {
	const { t } = useTranslation();
	const { apiData, apiStatus } = useCookbookApi({
		url: 'Ingredients',
		translate: t,
	});

	const breadcrumb = [{ name: t('Ingredients') }];

	return (
		<>
			<GenerateHeader title={t('Ingredients')} breadcrumb={breadcrumb} />
			<div className="container-boxes-small">
				<ToolBox createNew={true} />
			</div>
			{apiStatus}
			{apiData && apiData.data && !apiStatus && (
				<>
					{apiData.defaultTypes.IngredientsGroups.map((item, index) => {
						return (
							<div key={index}>
								<h4>{item.name}</h4>
								<Items
									items={apiData.data.filter(
										(filterData) => filterData.group_id === item.id
									)}
									link={'/Ingredients'}
									showSelector={true}
									elements={{
										images: t('Image'),
										'icon-name': t('Name'),
										description: t('Description'),
									}}
								/>
							</div>
						);
					})}
				</>
			)}
		</>
	);
}
