import React from 'react';
//import { useTranslation } from 'react-i18next';

export default function RecipesFilter(prop) {
	/*var recipesType = prop.recipesType;

	const [sort, setSort] = useState(prop.sort);
	const { t } = useTranslation();
*/
	return (
		<>
			{/*
				<div className="recipes-filter">
					<div className="recipes-filter-inner">
						{recipesType && recipesType.RecipesCategory ? (
							<div
								className="recipes-tool-box-item"
								title={t('Sort')}
								alt={t('Sort')}
							>
								<i className="fa fa-duotone fa-users duel_icon_color"></i>
								<span className="recipes-tool-box-item-info">
									<select
										value={sort}
										className="no_bg"
										onChange={(e) => setSort(e.target.value)}
									>
										{recipesType.RecipesCategory.map((item, index) => {
											console.log(item);
											<option key={index} value={item.id}>
												{t(item.name)}
											</option>;
										})}
									</select>
								</span>
							</div>
						) : (
							''
						)}
					</div>
				</div>
			*/}
		</>
	);
}
