import $ from 'jquery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mainTitle, MenuStructure } from '../../Config/PageConfig';
import { logout, userInfoString } from '../User/User';

function Navbar() {
	const [sidebar, setSidebar] = useState(false);

	const { t, i18n } = useTranslation();
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const showSidebar = () => {
		if (!sidebar) {
			$('body').addClass('show-menu');
		} else {
			$('body').removeClass('show-menu');
		}
		setSidebar(!sidebar);
	};

	return (
		<>
			<div className="navbar">
				<div className="navbar-inner">
					<div className="show-menu-button" onClick={showSidebar}>
						{sidebar ? (
							<i className="fa fa-close" />
						) : (
							<i className="fa fa-bars" />
						)}
					</div>

					<Link to="/" className="main-title nav-items">
						{mainTitle}
					</Link>

					<div className="logout nav-items">
						<i
							className="fa fa-right-from-bracket"
							onClick={logout}
							alt={t('Logout')}
							title={t('Logout')}
						/>
					</div>

					<div className="flags nav-items">
						{i18n && i18n.language === 'da' ? (
							<a
								onClick={() => changeLanguage('en')}
								alt={t('Change language to English')}
								title={t('Change language to English')}
							>
								EN
								{/*<div className="flag flag-en"></div>*/}
							</a>
						) : (
							<a
								onClick={() => changeLanguage('da')}
								alt={t('Change language to Danish')}
								title={t('Change language to Danish')}
							>
								DA
								{/*<div className="flag flag-da"></div>*/}
							</a>
						)}
					</div>

					{/*
					<li key={'languash-da'} className={'nav-text'}>
						<a onClick={() => changeLanguage('da')}>
							<i className="fa fa-solid fa-flag"></i>{' '}
							<span>{t('Change language to') + ' Dansk'}</span>
						</a>
					</li>
					<li key={'languash-en'} className={'nav-text'}>
						<a onClick={() => changeLanguage('en')}>
							<i className="fa fa-solid fa-flag-usa"></i>{' '}
							<span>{t('Change language to') + ' English'}</span>
						</a>
						</li>*/}

					<Link
						to="/account"
						className="account nav-items"
						alt={t('Account')}
						title={t('Account')}
					>
						<i className="fa fa-user"></i>
						<span className="account-text">{userInfoString()}</span>
					</Link>
				</div>
			</div>
			<nav className="nav-menu">
				<ul className="nav-menu-items" onClick={showSidebar}>
					{MenuStructure.map((item, index) => {
						return item.showInMenu ? (
							<li key={index} className={item.className}>
								<Link to={item.path}>
									{item.icon} <span>{t(item.title)}</span>
								</Link>
							</li>
						) : (
							''
						);
					})}
				</ul>
			</nav>
		</>
	);
}
export default Navbar;
