import $ from 'jquery';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { loginUser } from '../Components/User/User';
import { mainTitle } from '../Config/PageConfig';
export default function Login() {
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			username === null ||
			username === '' ||
			username === '' ||
			username === undefined ||
			password === null ||
			password === '' ||
			password === '' ||
			password === undefined
		) {
			return;
		}

		const response = await loginUser({
			username,
			password,
		});

		if (response === null || response.status === 'error') {
			if (response.message !== null && response.message !== undefined) {
				$('.error').text(response.message).slideDown().delay(2500).slideUp();
			}
		}
	};

	return (
		<>
			<div className="login-page">
				<div className="login-page-inner">
					<div className="logo-title">{mainTitle}</div>
					<h4>Connection to another world</h4>
				</div>
				<div className="panel panel-default">
					<div className="login-page-inner-bg"></div>
					<div className="panel-body">
						<h2>Login</h2>
						<form className="login-form" noValidate onSubmit={handleSubmit}>
							<label className="pt-3">Name</label>
							<Form.Control
								variant="outlined"
								margin="normal"
								id="email"
								name="email"
								label="Email Address"
								placeholder="Username / Email"
								onChange={(e) => setUserName(e.target.value)}
							/>
							<label className="pt-3">Password</label>
							<Form.Control
								variant="outlined"
								margin="normal"
								id="password"
								name="password"
								label="Password"
								type="password"
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<div className="error"></div>
							<Button variant="primary" type="submit" className="submit">
								Sign In
							</Button>
						</form>
						<p className="mt-3 footnote">
							Create by Rasmus R. Sørensen - r2times
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
