import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi';
import { Items } from '../../Components/Items/Items';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import AccountToolbox from './components/AccountToolbox';

export default function Accounts() {
	const { t } = useTranslation();
	const { apiData, apiStatus } = useCookbookApi({
		url: 'Users',
		translate: t,
	});

	const breadcrumb = [{ name: t('Users') }];

	return (
		<>
			<GenerateHeader title={t('Users')} breadcrumb={breadcrumb} />
			<div className="container-boxes-small">
				<AccountToolbox createNew={true} />
			</div>
			{apiStatus}
			{apiData && apiData.data && !apiStatus && (
				<>
					<Items
						items={apiData.data}
						link={'/Manage account'}
						showSelector={false}
						view={'list'}
						linkend={'/edit'}
						elements={{
							image: t('Image'),
							username: t('Username'),
							email: t('Email'),
							first_name_last_name: t('Name'),
							address: t('Address'),
							zip: t('Zip'),
							city: t('City'),
							phone: t('Mobile'),
							tools: t('Tools'),
						}}
					/>
				</>
			)}
		</>
	);
}
