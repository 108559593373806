import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
	HandleChange,
	HandleDelete,
	HandleSubmit,
} from '../../../Components/CookbookApi/';
import { InputField, SelectField } from '../../../Components/Fields';
import FileField from '../../../Components/Fields/FileField';
import { getLocalStorage } from '../../../Components/LocalStorage/LocalStorage';

export default function AccountForm(prop) {
	const [defaultTypeData, setDefaultTypeData] = useState(null);
	const [formData, setFormData] = useState(null);
	const [render, setRender] = useState(0);
	const navigate = useNavigate();
	var userData = getLocalStorage('user_data');

	useEffect(() => {
		if (prop.data != null) {
			if (prop.data && prop.data.data && prop.data.data.id > 0) {
				prop.data.data.password = '!!----------!!';
			}

			setDefaultTypeData(prop.data.defaultTypes);
			setFormData(prop.data.data);
		}
	}, [prop.data]);

	return (
		<>
			{formData && (
				<form onSubmit={(e) => HandleSubmit(e, 'Users', formData, '/')}>
					<div className="row">
						<div className="col-md-3">
							<FileField
								limit={10}
								value={[formData, setFormData, render, setRender]}
								name={'image'}
							/>
						</div>
						<div className="col-md-4">
							<SelectField
								value={formData.access_level_id && formData.access_level_id}
								isMulti={false}
								options={defaultTypeData.UserType && defaultTypeData.UserType}
								title={t('Account type')}
								name="access_level_id"
								onChange={(event) =>
									HandleChange(event, formData, setFormData, render, setRender)
								}
							/>

							<InputField
								name="username"
								value={formData.username}
								title={t('Username') + ' *'}
								required={true}
								onChange={(event) =>
									HandleChange(event, formData, setFormData, render, setRender)
								}
							/>

							<InputField
								name="password"
								value={formData.password}
								type={'password'}
								title={t('Password')}
								required={true}
								onChange={(event) =>
									HandleChange(event, formData, setFormData, render, setRender)
								}
							/>
						</div>

						<div className="col-md-5">
							<div className="row">
								<div className="col-xl-2">
									<SelectField
										value={formData.sex ? formData.sex : 'male'}
										isMulti={false}
										options={[
											{ id: 'Male', name: 'Male' },
											{ id: 'Female', name: 'Female' },
										]}
										title={t('Sex')}
										name="sex"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
								<div className="col-xl-5">
									<InputField
										name="first_name"
										value={formData.first_name}
										title={t('First name') + ' *'}
										required={true}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
								<div className="col-xl-5">
									<InputField
										name="last_name"
										value={formData.last_name}
										title={t('Last name') + ' *'}
										required={true}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
							</div>

							<InputField
								name="address"
								value={formData.address}
								title={t('Address')}
								onChange={(event) =>
									HandleChange(event, formData, setFormData, render, setRender)
								}
							/>

							<div className="row">
								<div className="col-3">
									<InputField
										name="zip"
										value={formData.zip > 0 ? formData.zip : ''}
										title={t('Zip')}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
								<div className="col-9">
									<InputField
										name="city"
										value={formData.city}
										title={t('City')}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-6">
									<InputField
										name="email"
										value={formData.email}
										title={t('Email')}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
								<div className="col-6">
									<InputField
										name="phone"
										value={formData.phone}
										title={t('Phone')}
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="button-box">
						{prop.backButton && prop.backButton !== '' && (
							<Button
								variant="secondary"
								className="float-left"
								onClick={(e) => navigate(prop.backButton)}
							>
								{t('Back')}
							</Button>
						)}
						<Button
							variant="primary"
							type="submit"
							className="submit float-right btn-lg"
						>
							{t('Save')}
						</Button>

						{formData && formData.id > 0 && userData.id !== formData.id ? (
							<Button
								variant="danger"
								className="float-right"
								style={{ marginRight: '20px' }}
								onClick={(e) => HandleDelete(e, formData, 'Users')}
							>
								{t('Delete')}
							</Button>
						) : (
							''
						)}
					</div>
				</form>
			)}
		</>
	);
}
