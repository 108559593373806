import { apiCookbookUrl } from '../../Config/PageConfig';
import { getLocalStorage, setLocalStorage } from '../LocalStorage/LocalStorage';

export const loginUser = async function (credentials) {
	try {
		var data = await fetch(apiCookbookUrl + 'Login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(credentials),
		});
		var response = await data.json();

		if (response.status === 'success') {
			setLocalStorage('is_login', true);
			setLocalStorage('user_data', response.data);

			window.location.href = window.location.pathname;
		} else {
			setLocalStorage('is_login', false);
		}

		return response;
	} catch (err) {
		console.log(err);
	}
};

export const userInfoString = function () {
	var userData = getLocalStorage('user_data');
	var returnString = '';
	if (userData !== null) {
		if (userData.first_name != null && userData.last_name != null) {
			if (userData.first_name !== '') {
				returnString += userData.first_name;
			}

			if (returnString !== '') {
				returnString += ' ';
			}

			if (userData.last_name !== '') {
				returnString += userData.last_name;
			}
		}

		if (userData.username !== null && returnString === '') {
			if (returnString !== '') {
				returnString += ' (' + userData.username + ')';
			} else {
				returnString += userData.username;
			}
		}
	}
	return returnString;
};

export const validateAccess = function (item_create_by_id, specifiedAccess) {
	const user_data = getLocalStorage('user_data');

	if (specifiedAccess && user_data.access_level === specifiedAccess) {
		return true;
	} else if (user_data.access_level === 'superadmin') {
		return true;
	} else if (user_data.id === item_create_by_id) {
		return true;
	} else {
		return false;
	}
};

export const logout = function (key) {
	setLocalStorage('is_login', false);
	setLocalStorage('user_data', {});
	window.location.href = window.location.pathname;
};
