import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi/useCookbookApi';
import { Items } from '../../Components/Items/Items';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import RecipesFilter from './components/RecipesFilter';
import RecipesShowToolBox from './components/RecipesShowToolBox';

function Recipes(props) {
	const { t } = useTranslation();

	var extraUrl = '';
	var title = t('Recipes');
	var titleFormat = 'h1';

	if (props.titleFormat) {
		titleFormat = props.titleFormat;
	}

	if (props.find_by_ingredient) {
		if (extraUrl !== '') {
			extraUrl += '&';
		}
		extraUrl += 'find_by_ingredient=' + props.find_by_ingredient;
	}

	if (extraUrl !== '') {
		extraUrl = '?' + extraUrl;
	}

	const { apiData, apiStatus } = useCookbookApi({
		url: 'Recipes' + extraUrl,
		translate: t,
	});

	var breadcrumb = [{ name: t('Recipes') }];

	if (props.noBreadcrub) {
		breadcrumb = null;
	}

	if (props.title) {
		title = props.title;
	}
	return (
		<>
			<GenerateHeader
				titleFormat={titleFormat}
				title={title}
				breadcrumb={breadcrumb}
			/>
			<div className="container-boxes-small">
				<RecipesShowToolBox createNew={props.createNew ? true : false} />
			</div>
			{apiStatus}
			{apiData && apiData.data && !apiStatus && (
				<>
					<RecipesFilter recipesType={apiData.defaultTypes} />
					<Items
						items={apiData.data}
						link={'/Recipes'}
						showSelector={true}
						elements={{
							images: t('Image'),
							'name-description': t('Name'),
							category_name: t('Category'),
						}}
					/>
				</>
			)}
		</>
	);
}

export default Recipes;
