import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { mainUploadPath } from '../../Config/PageConfig';
import { convertImageUrl } from '../CookbookApi';

export const ItemsCard = (props) => {
	const items = props.items;
	const title = props.title;
	var elements = props.elements;
	var link = props.link;

	return (
		<>
			<div className="card-items d-flex flex-wrap">
				{items.map((item, index) => {
					return (
						<React.Fragment key={title + ' ' + index}>
							<Link
								to={
									link &&
									link + '/' + item.id + (props.linkend ? props.linkend : '')
								}
								className={link ? 'card-item card clickable' : 'card-item card'}
							>
								{Object.keys(elements).map((key) => (
									<React.Fragment key={title + key + 'image' + item.id}>
										{(key === 'image' || key === 'images') &&
										item[key] !== undefined &&
										item[key][0] !== undefined ? (
											<div
												className="card-key-image"
												style={{
													backgroundImage: `url(${convertImageUrl(
														item[key][0],
														500
													)})`,
												}}
											></div>
										) : key === 'image' && item[key] !== undefined ? (
											<div
												className="card-key-image"
												style={{
													backgroundImage: `url(${mainUploadPath + item[key]})`,
												}}
											></div>
										) : (
											<></>
										)}
									</React.Fragment>
								))}

								<div className="card-body">
									{Object.keys(elements).map((key) => (
										<React.Fragment key={key + title + 'content' + item.id}>
											{key === 'image' || key === 'images' ? (
												<></>
											) : typeof item[key] === 'object' ? (
												<>
													{item[key] !== null && item[key].length > 0 && (
														<div className={'card-key-' + key}>
															{item[key].join(', ')}
														</div>
													)}
												</>
											) : item[key] !== '' && key === 'name-description' ? (
												<>
													<div className="card-key-name">{item['name']}</div>
													<div className="card-key-description">
														{item.description.length > 200
															? `${item.description.substring(0, 200)}...`
															: item.description}
													</div>
												</>
											) : item[key] !== '' && key === 'description' ? (
												<>
													<div className="card-key-description">
														{item.description.length > 200
															? `${item.description.substring(0, 200)}...`
															: item.description}
													</div>
												</>
											) : item[key] !== '' && key === 'icon-name' ? (
												<>
													<div className={'card-key-' + key}>
														{item['icon'] ? (
															<i className={item['icon'] + ' mr-10'}></i>
														) : (
															''
														)}
														{t(item['name'])}
													</div>
												</>
											) : (
												<div className={'card-key-' + key}>{t(item[key])}</div>
											)}
										</React.Fragment>
									))}
								</div>
							</Link>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};
