import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { validateAccess } from '../../../Components/User/User';

export default function RecipesShowToolBox(props) {
	const recipeData = props.recipeData;
	const persons = props.persons;
	const setPersons = props.setPersons;
	const toggleFavorite = props.toggleFavorite;
	const toggleLike = props.toggleLike;

	const { t } = useTranslation();
	const fnSetVersion = function (version) {
		console.log(version);
	};

	return (
		<div className="recipes-tool-box-items recipes-tool-box-first-row ">
			{recipeData && recipeData.viewed > 0 ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Viewed')}
					alt={t('Viewed')}
				>
					<i className="fa-solid fa-duotone fa-eye duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						{recipeData.viewed}
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && recipeData.avgLikes > 0 ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Like')}
					alt={t('Like')}
				>
					<i className="fa-solid fa-duotone fa-heart duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						{Math.floor(recipeData.avgLikes * 100) / 100}
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && recipeData.create_by_name > 0 ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Create by')}
					alt={t('Create by')}
				>
					<i className="fa-solid fa-duotone fa-user duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						{recipeData.create_by_name}
					</span>
				</div>
			) : (
				''
			)}

			{recipeData &&
			recipeData.category_name &&
			recipeData.category_name.length > 0 ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Categories')}
					alt={t('Categories')}
				>
					<i className="fa-solid fa-duotone fa-layer-group duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						{recipeData.category_name.join(', ')}
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && recipeData.kcal_pr_portion ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Kcal pr persons')}
					alt={t('Kcal')}
				>
					<i className="fa-solid fa-duotone fa-fire duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						{recipeData.kcal_pr_portion}
						{' ' + t('Kcal/per.')}
					</span>
				</div>
			) : (
				''
			)}
			{recipeData && recipeData.cooking_time > 0 ? (
				<div
					className="recipes-tool-box-item recipes-tool-box-item-info"
					title={t('Cooking time')}
					alt={t('Cooking time')}
				>
					<i className="fa-solid fa-duotone fa-timer duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						{recipeData.cooking_time}
						{' ' + t('Min.')}
					</span>
				</div>
			) : (
				''
			)}
			{
				recipeData && recipeData.prep_time > 0 ? (
					<div
						className="recipes-tool-box-item recipes-tool-box-item-info"
						title={t('Prep time')}
						alt={t('Prep time')}
					>
						<i className="fa-solid fa-duotone fa-hourglass-end duel_icon_color"></i>
						<span className="recipes-tool-box-item-info">
							{recipeData.prep_time}
							{' ' + t('Min.')}
						</span>
					</div>
				) : (
					''
				) /*<div className="clear"></div>*/
			}
			{
				recipeData && recipeData.status_name ? (
					<div
						className="recipes-tool-box-item recipes-tool-box-item-info"
						title={t('Status')}
						alt={t('Status')}
					>
						<i
							className={
								recipeData.status_access_level === 'all'
									? 'fa-duotone fa-eye duel_icon_color'
									: recipeData.status_access_level === 'superadmin'
									? 'fa-duotone fa-eye-low-vision duel_icon_color'
									: 'fa-duotone fa-eye-slash duel_icon_color'
							}
						></i>
						<span className="recipes-tool-box-item-info">
							{recipeData.status_name}
						</span>
					</div>
				) : (
					''
				) /*<div className="clear"></div>*/
			}
			{recipeData && persons ? (
				<div
					className="recipes-tool-box-item"
					title={t('Persons')}
					alt={t('Persons')}
				>
					<i className="fa fa-duotone fa-users duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						<select
							value={persons}
							className="no_bg"
							onChange={(e) => setPersons(e.target.value)}
						>
							<option value={1}>1 {t('Person')}</option>
							<option value={2}>2 {t('Persons')}</option>
							<option value={3}>3 {t('Persons')}</option>
							<option value={4}>4 {t('Persons')}</option>
							<option value={5}>5 {t('Persons')}</option>
							<option value={6}>6 {t('Persons')}</option>
							<option value={7}>7 {t('Persons')}</option>
							<option value={8}>8 {t('Persons')}</option>
							<option value={9}>9 {t('Persons')}</option>
							<option value={10}>10 {t('Persons')}</option>
						</select>
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && recipeData.version > 0 ? (
				<div
					className="recipes-tool-box-item"
					title={t('Version')}
					alt={t('Version')}
				>
					<i className="fa-solid fa-duotone fa-code-compare duel_icon_color"></i>
					<span className="recipes-tool-box-item-info">
						<select
							value={recipeData.version}
							onChange={(e) => fnSetVersion(e.target.value)}
							className="no_bg"
						>
							<option value={1}>{t('Version')} 1</option>
						</select>
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && toggleFavorite ? (
				<div
					className="recipes-tool-box-item"
					title={t('Favorites')}
					alt={t('Favorites')}
				>
					<i
						className={
							recipeData && recipeData.favorite
								? 'fa-solid  fa-star duel_icon_color'
								: 'fa-solid fa-duotone fa-star duel_icon_color'
						}
					></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						{recipeData && recipeData.favorite ? (
							<a
								href="toggle"
								className="remove-underline"
								onClick={(e) => toggleFavorite(e, false)}
							>
								{t('Remove to favorites')}
							</a>
						) : (
							<a
								href="toggle"
								className="remove-underline"
								onClick={(e) => toggleFavorite(e, true)}
							>
								{t('Add to favorites')}
							</a>
						)}
					</span>
				</div>
			) : (
				''
			)}

			{recipeData && toggleLike ? (
				<div
					className="recipes-tool-box-item box-item-like"
					title={t('Like')}
					alt={t('Like')}
				>
					<div className="hover-box">
						<i
							className={'fa-solid fa-duotone  fa-circle-minus'}
							onClick={(e) => toggleLike(e, 0)}
						></i>
						<i
							className={'fa-solid fa-duotone fa-heart'}
							onClick={(e) => toggleLike(e, 1)}
						></i>
						<i
							className={'fa-solid fa-duotone fa-heart'}
							onClick={(e) => toggleLike(e, 2)}
						></i>
						<i
							className={'fa-solid fa-duotone fa-heart'}
							onClick={(e) => toggleLike(e, 3)}
						></i>
						<i
							className={'fa-solid fa-duotone fa-heart'}
							onClick={(e) => toggleLike(e, 4)}
						></i>
						<i
							className={'fa-solid fa-duotone fa-heart'}
							onClick={(e) => toggleLike(e, 5)}
						></i>
					</div>
					<div>
						{recipeData && recipeData.likes > 0 && recipeData.likes + ' x '}
						<i
							className={
								recipeData && recipeData.likes > 0
									? 'fa-solid fa-heart duel_icon_color'
									: 'fa-solid fa-duotone fa-heart duel_icon_color'
							}
						></i>
						<span
							className="recipes-tool-box-item-info"
							style={{ width: 'auto' }}
						>
							{recipeData && recipeData.likes > 0 ? t('Like') : t('Like')}
						</span>
					</div>
				</div>
			) : (
				''
			)}

			{recipeData &&
			recipeData.create_by &&
			validateAccess(recipeData.create_by, 'superadmin') ? (
				<div className="recipes-tool-box-item" title="Edit" alt="Edit">
					<i className="fa-solid  fa-pen duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						{recipeData && (
							<Link
								to={'/recipes/' + recipeData.id + '/edit'}
								className="remove-underline"
							>
								{t('Edit recipe')}
							</Link>
						)}
					</span>
				</div>
			) : (
				''
			)}
			{props.createNew && validateAccess(null, 'admin') ? (
				<div className="recipes-tool-box-item" title="Create" alt="Create">
					<i className="fa-solid fa-solid fa-plus duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						<Link to={'/recipes/create'} className="remove-underline">
							{t('Create new recipes')}
						</Link>
					</span>
				</div>
			) : (
				''
			)}
			<div className="clear"></div>
		</div>
	);
}
