import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../../Components/CookbookApi';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import { validateAccess } from '../../Components/User/User';
import RecipesForm from './components/RecipesForm';

export default function RecipeCreate() {
	const { t } = useTranslation();

	const { apiData } = new useCookbookApi({
		url: 'Recipes/new',
		translate: t,
	});

	const breadcrumb = [
		{ link: '/Recipes', name: t('Recipes') },
		{ name: t('Create new recipe') },
	];

	return (
		<>
			<GenerateHeader title={t('Create new recipe')} breadcrumb={breadcrumb} />
			{apiData ? (
				validateAccess(null, 'admin') ? (
					<RecipesForm dataResult={apiData} />
				) : (
					'No access'
				)
			) : (
				''
			)}
		</>
	);
}
