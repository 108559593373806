import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCookbookApi } from '../../Components/CookbookApi';
import { getLocalStorage } from '../../Components/LocalStorage/LocalStorage';
import GenerateHeader from '../../Components/UI/GenerateHeader';
import AccountForm from './components/AccountForm';

function Account() {
	const userData = getLocalStorage('user_data');
	const { t } = useTranslation();

	const { userId } = useParams();

	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Users/' + (userId ? userId : userData.id),
		translate: t,
	});

	var breadcrumb = [{ link: '/Account', name: t('Account') }];

	return (
		<>
			<GenerateHeader title={t('Account')} breadcrumb={breadcrumb} />
			{apiStatus}
			{!apiStatus && apiData ? <AccountForm data={apiData} /> : ''}
		</>
	);
}

export default Account;
