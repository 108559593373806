import { t } from 'i18next';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { convertImageUrl } from '../CookbookApi';

export default function FileField(props) {
	const name = props.name ? props.name : 'default';
	const value = props.value ? props.value : [];
	var limit = props.limit ? props.limit : 999999999999999;
	var startLimit = false;
	if (
		limit &&
		value &&
		value[0] &&
		value[0][name] &&
		value[0][name].length >= limit
	) {
		startLimit = true;
	} else {
		startLimit = false;
	}

	if (!props.multiple) {
		limit = 1;
	}

	const [limitReact, setLimitReact] = useState(startLimit);
	const getFileType = (file) => {
		if (file.type.indexOf('video') !== -1) {
			return 'video';
		} else if (file.type.indexOf('image') !== -1) {
			return 'image';
		}
		return null;
	};

	const stepRemoveHandler = (event, index) => {
		event.preventDefault();
		var imageTmp = value[0];
		var stepData = [...imageTmp[name]];

		stepData.splice(index, 1);
		imageTmp[name] = stepData;

		if (limit && imageTmp[name].length < limit) {
			setLimitReact(false);
		} else {
			setLimitReact(true);
		}
		value[1](imageTmp);
		value[3](value[2] + 1);
	};

	const handleUploadFiles = async (e, files) => {
		var imageTmp = value[0];

		if (!imageTmp[name]) {
			imageTmp[name] = [];
		}

		for (var i = 0; i < files.length; i++) {
			const fileType = getFileType(files[i]);
			if (!fileType) {
				alert(t('Wrong file type only images'));
				continue;
			}
			const base64 = await convertBase64(files[i]);
			if (props.multiple) {
				if (limit && imageTmp[name].length < limit) {
					imageTmp[name].push('0|' + files[i].name + '|' + base64);

					if (limit && imageTmp[name].length >= limit) {
						setLimitReact(true);
					} else {
						setLimitReact(false);
					}
				} else {
					setLimitReact(true);
					alert(
						t('You can only add maximum of') + ' ' + limit + ' ' + t('files')
					);
					break;
				}
			} else {
				imageTmp[name] = ['0|' + files[i].name + '|' + base64];
			}
		}
		value[1](imageTmp);
		value[3](value[2] + 1);
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const handleUpload = (e) => {
		if (e.target.files) {
			const chosenFiles = Array.prototype.slice.call(e.target.files);
			handleUploadFiles(e, chosenFiles);
			return false;
		}
	};

	var attr = { type: 'file' };

	if (props.accept) {
		attr.accept = props.accept;
	} else {
		attr.accept = 'image/*';
	}

	if (props.multiple) {
		attr.multiple = props.multiple;
	}
	attr.onChange = (events) => handleUpload(events);
	attr.onDragEnter = (events) =>
		events.target.parentElement.classList.add('dragover');
	attr.onDragLeave = (events) =>
		events.target.parentElement.classList.remove('dragover');
	attr.onDragOver = (events) =>
		events.target.parentElement.classList.add('dragover');
	attr.onDrop = (events) =>
		events.target.parentElement.classList.remove('dragover');

	/*const handleSort = () => {
		console.log(dragItem);
		console.log(dragOverItem);

		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(dragItem, 1)[0];

		stepData.splice(dragOverItem, 0, draggedItemContent);

		dragItem = null;
		dragOverItem = null;
		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};*/

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		var dataArray = value[0];
		var stepData = [...dataArray[name]];

		const draggedItemContent = stepData.splice(result.source.index, 1)[0];

		stepData.splice(result.destination.index, 0, draggedItemContent);

		dataArray[name] = stepData;
		value[1](dataArray);
		value[3](value[2] + 1);
	};

	return (
		<>
			<div
				className={
					(value[0][name] && value[0][name].length > 0
						? 'has-images file-field  mb-4'
						: 'file-field mb-4') +
					' ' +
					(props.multiple ? 'multi' : '')
				}
			>
				{!limitReact && (
					<>
						<div className="file-field-info">
							<h5>{t('Drag to upload file')}</h5>
							<p>{t('or')}</p>
							<button
								className="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									e.target.parentElement.nextSibling.click();
								}}
							>
								{props.multiple ? t('Upload files') : t('Upload file')}
							</button>
						</div>
						<input {...attr}></input>
					</>
				)}
				<div className="drag-view">
					<div className="drag-view-inner">{'Drag'}</div>
				</div>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable" direction="horizontal">
						{(provided, snapshot) => (
							<div
								className="images"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{!limitReact &&
									value[0][name] &&
									value[0][name].length > 0 &&
									props.multiple && <div className="dummy image"></div>}
								{value[0][name] &&
									value[0][name].map((item, index) => {
										return (
											<Draggable
												key={index}
												draggableId={index.toString()}
												index={index}
											>
												{(provided, snapshot) => {
													const style = {
														...provided.draggableProps.style,
														backgroundImage: `url(${convertImageUrl(item)})`,
													};
													return (
														<div
															className={
																'image ' +
																(snapshot.isDragging ? ' isDragging' : '')
															}
															{...provided.dragHandleProps}
															{...provided.draggableProps}
															ref={provided.innerRef}
															style={style}

															/*draggable
												onDragStart={(e) => {
													console.log('Start' + index);
													dragItem = index;
												}}
												onDragEnter={(e) => {
													console.log('enter' + index);
													dragOverItem = index;
												}}
												onDragEnd={handleSort}
												onDragOver={(e) => {
													e.preventDefault();
												}}*/
															/*style={{
															backgroundImage: `url(${convertImageUrl(item)})`,
														}}*/
														>
															<div
																className="close"
																onClick={(event) => {
																	stepRemoveHandler(event, index);
																}}
															>
																<i className="fa-solid fa-circle-xmark"></i>
															</div>
															<input
																name={name + '[]'}
																type={'hidden'}
																value={item}
															/>
														</div>
													);
												}}
											</Draggable>
										);
									})}
								{provided.placeholder}
								<div className="clear"></div>
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	);
}
