import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	HandleChange,
	HandleDelete,
	HandleSubmit,
} from '../../../Components/CookbookApi';
import {
	InputField,
	SelectField,
	TextAreaField,
} from '../../../Components/Fields';
import FileField from '../../../Components/Fields/FileField';

export default function IngredientForm(prop) {
	var dataResult = prop.dataResult;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formData, setFormData] = useState(null);
	const [defaultTypeData, setDefaultTypeData] = useState(null);
	const [render, setRender] = useState(0);
	useEffect(() => {
		if (dataResult != null) {
			setDefaultTypeData(dataResult.defaultTypes);
			setFormData(dataResult.data);
		}
	}, [dataResult]);

	return (
		<div>
			{formData && (
				<div className="main-ingredient">
					<div className="main-ingredient-inner">
						<form
							className="login-form"
							noValidate
							onSubmit={(e) => HandleSubmit(e, 'Ingredients', formData)}
						>
							<div className="row">
								<div className="col-md-6">
									<InputField
										name="name"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.name}
										title={t('Title')}
									/>

									<TextAreaField
										name="description"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.description}
										title={t('Description')}
									/>
								</div>
								<div className="col-md-6">
									<SelectField
										value={formData.group_id}
										isMulti={false}
										options={defaultTypeData.IngredientsGroups}
										title={t('Category')}
										name="group_id"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>

									<InputField
										name="icon"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.icon}
										title={t('Font-awesome Icon')}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<h3 className="mt-4">{t('Images')}</h3>
									<FileField
										limit={11}
										value={[formData, setFormData, render, setRender]}
										multiple={'multiple'}
										name={'images'}
									/>
								</div>
							</div>
							<div className="button-box">
								<Button
									variant="primary"
									type="submit"
									className="submit float-right btn-lg"
								>
									{t('Save')}
								</Button>
								<Button
									variant="secondary"
									className="float-left"
									onClick={(e) =>
										navigate(
											'/Ingredients' +
												(dataResult.data.id > 0 ? '/' + dataResult.data.id : '')
										)
									}
								>
									{t('Back')}
								</Button>
								{dataResult && dataResult.data.id > 0 ? (
									<>
										<Button
											variant="danger"
											className="float-right"
											style={{ marginRight: '20px' }}
											onClick={(e) =>
												HandleDelete(e, dataResult.data, 'Ingredients')
											}
										>
											{t('Delete')}
										</Button>
									</>
								) : (
									''
								)}
								<div className="clear"></div>
							</div>
						</form>
					</div>
				</div>
			)}
		</div>
	);
}
