//import React from 'react';
import { apiCookbookUrl } from '../../Config/PageConfig';
import { getLocalStorage } from '../LocalStorage/LocalStorage';

export const useCookbookApiDelete = async function (url, body) {
	var data = null;
	var isPending = true;
	var errorMessage = null;

	try {
		const userData = getLocalStorage('user_data');
		const header = { 'Content-Type': 'application/json' };
		if (userData.token[2] !== undefined && userData.token[2] !== '') {
			header.Authorization = 'Basic ' + userData.token[2];
		}

		const communicate = {};
		communicate.method = 'DELETE';
		communicate.headers = header;
		communicate.body = JSON.stringify(body);
		var data_feed = await fetch(apiCookbookUrl + url, communicate);

		if (!data_feed.ok) {
			throw Error(
				'Unable to fetch data from server code: ' +
					data.status +
					' (' +
					data.statusText +
					')'
			);
		}

		data = await data_feed.json();
		errorMessage = null;
		isPending = false;
	} catch (err) {
		errorMessage = 'Connection error, try again later';
		isPending = false;
		data = null;
		console.log(err.message);
	}

	return { data, isPending, errorMessage };
};

export default useCookbookApiDelete;
