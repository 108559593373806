import PageConfig from '../../Config/PageConfig';
import Error404 from '../../Pages/Error404';
import Login from '../../Pages/Login';
import { getLocalStorage } from '../LocalStorage/LocalStorage';
import Footer from './Footer';
import Header from './Header';

export const DefaultPage = (props) => {
	const showElement = props.showElement;
	const path = props.path;
	var elements = '';
	if (!getLocalStorage('is_login')) {
		elements = (
			<>
				<PageConfig path={'/Login'} />
				<Login />
			</>
		);
	} else if (path === '*') {
		elements = (
			<>
				<PageConfig path={path} />
				<Error404 />
			</>
		);
	} else {
		elements = (
			<>
				<div className="main-loader-element">
					<div className="main-loader-element-inner">
						<i className="fa-solid fa-loader"></i>
					</div>
				</div>
				<Header />
				<div className="main-content">
					<div className="main-content-inner">
						{getLocalStorage('is_login') ? showElement : <Login />}
					</div>
				</div>
				<Footer />
				<PageConfig path={path} />
			</>
		);
	}
	return elements;
};
