export const FetchLoader = (props) => {
	const errorMessage = props.errorMessage;
	const isPending = props.isPending;
	return (
		<>
			{errorMessage && (
				<div className="error">
					<div className="error-inner">{errorMessage}</div>
				</div>
			)}
			{isPending && !errorMessage && (
				<div className="loading">
					<div className="loading-inner">
						{props.loadingText ? props.loadingText : ''}
					</div>
				</div>
			)}
		</>
	);
};
