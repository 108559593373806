import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import $ from 'jquery';

import { t } from 'i18next';
import React from 'react';
import Account from '../Pages/Account/Account';
import AccountCreate from '../Pages/Account/AccountCreate';
import AccountEdit from '../Pages/Account/AccountEdit';
import Accounts from '../Pages/Account/Accounts';
import Error404 from '../Pages/Error404';
import HtmlTestPage from '../Pages/HtmlTestPage';
import Ingredient from '../Pages/Ingredients/Ingredient';
import IngredientCreate from '../Pages/Ingredients/IngredientCreate';
import IngredientEdit from '../Pages/Ingredients/IngredientEdit';
import Ingredients from '../Pages/Ingredients/Ingredients';
import Login from '../Pages/Login';
import MainPage from '../Pages/MainPage';
import Recipe from '../Pages/Recepes/Recipe';
import RecipeCreate from '../Pages/Recepes/RecipeCreate';
import RecipeEdit from '../Pages/Recepes/RecipeEdit';
import Recipes from '../Pages/Recepes/Recipes';
import { default as Technician } from '../Pages/Technician/Technician';

export const mainTitle = 'Cookbook 3.0';
export const mainUploadPath = '/uploads/';
export const mainImagePath = '/images/';
export const apiCookbookUrl = 'https://rest.unrealhuman.dk/';

export const getSiteInfoByPath = function (condition) {
	var returnData = MenuStructure.filter(
		(e) => e.path.toLowerCase() === condition.toLowerCase()
	);
	if (returnData !== 'null') {
		return returnData[0];
	}
	return returnData;
};

export const MenuStructure = [
	{
		title: t('Home'),
		siteTitle: '',
		path: '/',
		icon: <i className="fa fa-home" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-home',
		component: <MainPage />,
	},
	{
		title: t('Recipes'),
		siteTitle: t('Recipes'),
		path: '/Recipes',
		icon: <i className="fa fa-pot-food" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-recipes',
		component: <Recipes createNew={true} />,
	},
	{
		title: t('Recipe'),
		siteTitle: t('Recipe'),
		path: '/Recipes/:recipeId',
		icon: <i className="fa fa-pot-food" />,
		showInMenu: false,
		component: <Recipe />,
	},
	{
		title: t('Edit recipe'),
		siteTitle: t('Edit recipe'),
		path: '/Recipes/:recipeId/Edit',
		icon: <i className="fa fa-pot-food" />,
		showInMenu: false,
		component: <RecipeEdit />,
	},
	{
		title: t('Create recipe'),
		siteTitle: t('Create recipe'),
		path: '/Recipes/Create',
		icon: <i className="fa fa-pot-food" />,
		showInMenu: false,
		component: <RecipeCreate />,
	},

	{
		title: t('Ingredients'),
		siteTitle: t('Ingredients'),
		path: '/Ingredients',
		icon: <i className="fa fa-mortar-pestle" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-spices',
		component: <Ingredients />,
	},
	{
		title: t('Ingredients'),
		siteTitle: t('Ingredients'),
		path: '/Ingredients/:ingredientsId',
		icon: <i className="fa fa-mortar-pestle" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-spices',
		component: <Ingredient />,
	},
	{
		title: t('Edit ingredients'),
		siteTitle: t('Edit ingredients'),
		path: '/Ingredients/:ingredientsId/edit',
		icon: <i className="fa fa-mortar-pestle" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-spices',
		component: <IngredientEdit />,
	},
	{
		title: t('Create ingredients'),
		siteTitle: t('Create ingredients'),
		path: '/Ingredients/Create',
		icon: <i className="fa fa-mortar-pestle" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-spices',
		component: <IngredientCreate />,
	},

	{
		title: t('Technician'),
		siteTitle: t('Technician'),
		path: '/technician',
		icon: <i className="fa  fa-pan-food" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-spices',
		component: <Technician />,
	},

	{
		title: t('Manage account'),
		siteTitle: t('Manage account'),
		path: '/Users',
		icon: <i className="fa fa-users" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-accounts',
		component: <Accounts />,
	},

	{
		title: t('Edit account'),
		siteTitle: t('Edit account'),
		path: '/Users/:userId/Edit',
		icon: <i className="fa fa-users" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-accounts',
		component: <AccountEdit />,
	},

	{
		title: t('Create user'),
		siteTitle: t('Create user'),
		path: '/Users/Create',
		icon: <i className="fa fa-users" />,
		showInMenu: false,
		component: <AccountCreate />,
	},

	{
		title: t('Css Test page'),
		siteTitle: t('Css Test page'),
		path: '/HtmlTestPage',
		icon: <i className="fa fa-vials" />,
		showInMenu: true,
		className: 'nav-text',
		bodyClassName: 'page-test',
		component: <HtmlTestPage />,
	},

	// -----------------------------
	// Special pages - 404 and login
	// -----------------------------

	{
		title: t('Account'),
		siteTitle: t('Account'),
		path: '/account',
		icon: <i className="fa fa-users" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-accounts',
		component: <Account />,
	},

	{
		title: t('Login'),
		siteTitle: t('Login'),
		path: '/Login',
		icon: <i className="fa fa-vials" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-loading',
		component: <Login />,
	},
	{
		title: t('404'),
		siteTitle: t('404'),
		path: '*',
		icon: <i className="fa fa-vials" />,
		showInMenu: false,
		className: 'nav-text',
		bodyClassName: 'page-404',
		component: <Error404 />,
	},
];

export default function PageConfig(props) {
	const [ConfigPath] = useState(props.path);
	const location = useLocation();
	useEffect(() => {
		updatePageData(location);
	}, [location, ConfigPath]);
}
export const updatePageData = function (ConfigPath) {
	if (!ConfigPath && !ConfigPath.pathname) {
		return false;
	}

	const currentUrl = ConfigPath.pathname;

	var string = mainTitle;
	var removeClass = $('body').attr('data-page');
	$('body').removeClass(removeClass);
	$('body').attr('data-page', '');

	var siteInfo = getSiteInfoByPath(currentUrl);

	if (
		siteInfo !== undefined &&
		siteInfo.siteTitle !== null &&
		siteInfo.siteTitle !== '' &&
		siteInfo.siteTitle !== undefined
	) {
		string += ' - ' + siteInfo.siteTitle;
	}
	if (
		siteInfo !== undefined &&
		siteInfo.bodyClassName !== null &&
		siteInfo.bodyClassName !== '' &&
		siteInfo.bodyClassName !== undefined
	) {
		$('body').attr('data-page', siteInfo.bodyClassName);
		$('body').addClass(siteInfo.bodyClassName);
	}

	document.title = '' + string;
};
