import { Link } from 'react-router-dom';

const generateBreadCrumb = function (breadcrumb) {
	return (
		<div className="breadcrumb-main">
			<div className="breadcrumb-main-inner">
				<div className="breadcrumb-items">
					<div className="breadcrumb-item">
						<Link to={'/'}>
							<i className="fa-solid fa-house"></i>
						</Link>
					</div>

					{breadcrumb &&
						breadcrumb.map((item, index) => {
							return (
								<div className="breadcrumb-item" key={index}>
									{item.name && item.link ? (
										<>
											<Link to={item.link}>{item.name}</Link>
										</>
									) : item.name ? (
										item.name
									) : (
										''
									)}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default function GenerateHeader(props) {
	var title = props.title ? props.title : '';
	const titleFormat = props.titleFormat ? props.titleFormat : 'h1';
	const breadcrumb = props.breadcrumb ? props.breadcrumb : '';

	if (titleFormat === 'h1') {
		title = title ? <h1>{title}</h1> : '';
	} else if (titleFormat === 'h2') {
		title = title ? <h2>{title}</h2> : '';
	} else if (titleFormat === 'h3') {
		title = title ? <h3>{title}</h3> : '';
	} else if (titleFormat === 'h4') {
		title = title ? <h4>{title}</h4> : '';
	} else if (titleFormat === 'h5') {
		title = title ? <h5>{title}</h5> : '';
	}

	return (
		<>
			{title}
			{breadcrumb ? generateBreadCrumb(breadcrumb) : ''}
		</>
	);
}
