//import React from 'react';
import { getLocalStorage } from '../LocalStorage/LocalStorage';

export const useCookbookApiSet = async function (communicate) {
	var apiData = null;
	var errorMessage = null;

	try {
		const header = { 'Content-Type': 'application/json' };

		const userData = getLocalStorage('user_data');
		if (userData.token[2] !== undefined && userData.token[2] !== '') {
			header.Authorization = 'Basic ' + userData.token[2];
		}

		if (!communicate.method) {
			communicate.method = 'GET';
		}
		if (!communicate.url) {
			communicate.url = '';
		}

		communicate.headers = header;
		if (communicate.body) {
			communicate.body = JSON.stringify(communicate.body);
		}

		var data_feed = await fetch(
			'https://rest.unrealhuman.dk/' + communicate.url,
			communicate
		);

		apiData = await data_feed.json();
	} catch (err) {
		errorMessage = 'Connection error, try again later';
		if (communicate.translate) {
			errorMessage = communicate.translate(errorMessage);
		}
	}

	return { apiData, errorMessage };
};

export default useCookbookApiSet;
