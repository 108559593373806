import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	HandleChange,
	HandleDelete,
	HandleSubmit,
} from '../../../Components/CookbookApi';
import {
	InputField,
	SelectField,
	TextAreaField,
} from '../../../Components/Fields';
import FileField from '../../../Components/Fields/FileField';
import RecipesEditIngredientsV2 from './RecipesEditIngredientsV2';
import RecipesEditStepsV2 from './RecipesEditStepsV2';

export default function RecipesForm(prop) {
	var dataResult = prop.dataResult;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formData, setFormData] = useState(null);
	const [defaultTypeData, setDefaultTypeData] = useState(null);
	const [render, setRender] = useState(0);
	useEffect(() => {
		if (dataResult != null) {
			setDefaultTypeData(dataResult.defaultTypes);
			setFormData(dataResult.data);
		}
	}, [dataResult]);

	return (
		<div>
			{formData && (
				<div className="main-recipe">
					<div className="main-recipe-inner">
						<form
							className="login-form"
							noValidate
							onSubmit={(e) => HandleSubmit(e, 'Recipes', formData)}
						>
							<div className="row">
								<div className="col-md-8">
									<InputField
										name="name"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.name}
										title={t('Title')}
									/>

									<TextAreaField
										name="description"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.description}
										title={t('Description')}
									/>
									<TextAreaField
										name="tip"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.tip}
										title={t('Tip')}
									/>

									<SelectField
										value={formData.category_ids}
										isMulti={true}
										options={defaultTypeData.RecipesCategory}
										title={t('Category')}
										name="category_ids"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>

									<SelectField
										value={formData.status_id && formData.status_id}
										isMulti={false}
										options={
											defaultTypeData.RecipesStatus &&
											defaultTypeData.RecipesStatus
										}
										title={t('Status')}
										name="status_id"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
									/>
								</div>
								<div className="col-md-4">
									<InputField
										name="cooking_time"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										value={formData.cooking_time}
										type="number"
										title={t('Cooking time') + ' (min)'}
									/>

									<InputField
										name="prep_time"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.prep_time}
										title={t('Prep time') + ' (min)'}
									/>
									<InputField
										name="quantity"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.quantity}
										title={t('Number of potions')}
									/>
									<InputField
										name="kcal_pr_portion"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.kcal_pr_portion}
										title={t('Kcal')}
									/>

									<InputField
										name="carbohydrates_pr_portion"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.carbohydrates_pr_portion}
										title={t('Carbohydrates') + ' (g)'}
									/>

									<InputField
										name="fat_pr_portion"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.fat_pr_portion}
										title={t('Fat') + ' (g)'}
									/>

									<InputField
										name="protein_pr_portion"
										onChange={(event) =>
											HandleChange(
												event,
												formData,
												setFormData,
												render,
												setRender
											)
										}
										type="number"
										value={formData.protein_pr_portion}
										title={t('Protein') + ' (g)'}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<h3 className="mt-4">{t('Images')}</h3>
									<FileField
										limit={11}
										value={[formData, setFormData, render, setRender]}
										multiple={'multiple'}
										name={'images'}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">
									<h3 className="mt-4">{t('Recipes steps')}</h3>
									<RecipesEditStepsV2
										value={[formData, setFormData, render, setRender]}
										name={'steps'}
									/>
								</div>
								<div className="col-md-6">
									<h3 className="mt-4">{t('Recipes ingredients')}</h3>
									<RecipesEditIngredientsV2
										value={[formData, setFormData, render, setRender]}
										defaultTypeData={defaultTypeData}
										name={'ingredients'}
									/>

									{/*formData.ingredients.map((item, index) => {
										return (
											<li key={index} className={'mb-3'}>
												<div class="input-group">
													<InputField
														title={'Step ' + (index + 1)}
														name={'text'}
														value={item.text}
														noMargin={true}
														onChange={(event) => stepHandler(event, index)}
													/>
													<Button
														onClick={(event) => stepRemoveHandler(event, index)}
													>
														remove
													</Button>
												</div>
											</li>
										);
									})*/}
								</div>
							</div>
							<div className="button-box">
								<Button
									variant="primary"
									type="submit"
									className="submit float-right btn-lg"
								>
									{t('Save')}
								</Button>

								<Button
									variant="secondary"
									className="float-left"
									onClick={(e) =>
										navigate(
											'/Recipes' +
												(dataResult.data.id > 0 ? '/' + dataResult.data.id : '')
										)
									}
								>
									{t('Back')}
								</Button>

								{dataResult && dataResult.data.id > 0 ? (
									<Button
										variant="danger"
										className="float-right"
										style={{ marginRight: '20px' }}
										onClick={(e) => HandleDelete(e, dataResult.data, 'Recipes')}
									>
										{t('Delete')}
									</Button>
								) : (
									''
								)}
								<div className="clear"></div>
							</div>
						</form>
					</div>
				</div>
			)}
		</div>
	);
}
