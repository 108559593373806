import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookbookApi } from '../Components/CookbookApi';
import { Items } from '../Components/Items/Items';

export default function MainPage() {
	const { t } = useTranslation();
	const { apiData, apiStatus } = new useCookbookApi({
		url: 'Recipes?filter=favorits',
		translate: t,
	});

	const [recipesData, setRecipesData] = useState(null);

	useEffect(() => {
		if (apiData != null && apiData.data != null) {
			setRecipesData(apiData.data);
		}
	}, [apiData]);

	return (
		<>
			<div className="list-recipes">
				<div className="list-recipes-inner">
					<h1>{t('My favorites')}</h1>
					{apiStatus}
					{recipesData && (
						<>
							<Items
								defaultText={t('You have not add any recipes to my favorites')}
								link={'/Recipes'}
								elements={{
									images: 'Images',
									'name-description': 'Name',
									category_name: 'Category',
								}}
								items={recipesData}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
}
