import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { validateAccess } from '../../../Components/User/User';

export default function ToolBox(props) {
	const recipeData = props.recipeData;
	const { t } = useTranslation();
	return (
		<div className="recipes-tool-box-items recipes-tool-box-first-row ">
			{recipeData &&
			recipeData.create_by &&
			validateAccess(recipeData.create_by, 'superadmin') ? (
				<div className="recipes-tool-box-item" title="Edit" alt="Edit">
					<i className="fa-solid  fa-pen duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						{recipeData && (
							<Link
								to={'/Ingredients/' + recipeData.id + '/edit'}
								className="remove-underline"
							>
								{t('Edit ingredients')}
							</Link>
						)}
					</span>
				</div>
			) : (
				''
			)}
			{props.createNew && validateAccess(null, 'admin') ? (
				<div className="recipes-tool-box-item" title="Create" alt="Create">
					<i className="fa-solid fa-solid fa-plus duel_icon_color"></i>
					<span
						className="recipes-tool-box-item-info"
						style={{ width: 'auto' }}
					>
						<Link to={'/Ingredients/create'} className="remove-underline">
							{t('Create new ingredients')}
						</Link>
					</span>
				</div>
			) : (
				''
			)}
			<div className="clear"></div>
		</div>
	);
}
