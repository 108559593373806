import React from 'react';

import i18n from './Components/i18n/i18n';
// router
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// menu
import { DefaultPage } from './Components/UI/defaultPage';
import { MenuStructure } from './Config/PageConfig';

// css
import './assets/css/style.min.css';

const content = ReactDOM.createRoot(document.getElementById('root'));
content.render(
	<>
		<Router>
			<Routes>
				{MenuStructure.map((item, index) => {
					return (
						<Route
							key={index}
							path={item.path}
							title={item.title}
							element=<DefaultPage
								showElement={item.component}
								path={item.path}
							/>
						/>
					);
				})}
			</Routes>
		</Router>
	</>
);
